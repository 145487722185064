import React,{useState}from 'react';
import './index.less';

function SiderImg() {
  const [display_sideImg, setdisplay_sideImg] = useState('block')
  return (
   <>
      <div className="side-img-container" style={{ display: display_sideImg }}>
        <button className="close-sideImg" onClick={() => setdisplay_sideImg('none')}>
          <img src='https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/wrong.png?sign=2748ca2c8cd1d9183d2df8615612c522&t=1650978069' alt='true'/>
        </button>
        <img className="side-img" src="https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_img/siderImg.jpeg?sign=7bcc1b9c3d5040fcf97cfc1135a5e2ee&t=1653291130" alt="true" />
      </div>  
   </>
  );
}

export default SiderImg;

import React from 'react';
import { Table, Tag, Space, Typography } from 'antd';
import Mock from 'mockjs';
const { Link } = Typography;
const Interview = () => {
  const delivery = Mock.mock({
    'data|1-10': [
      {
        'id|+1': 1,
        'name|1': ['京东', '美团', '字节跳动', '腾讯', '阿里巴巴', '快手', '网易', '新浪'],
        'position|1': ['财务分析专员', '产品经历', '平台运营', '算法工程师', '产品运营-商业产品'],
        'type|1': ['2022应届生', '社招生', '2023届应届生'],
        'time|1': [
          '2022-04-19',
          '2022-04-15',
          '2022-04-14',
          '2022-04-03',
          '2022-04-27',
          '2022-03-30',
        ],
        'tags|1': [['进行中'], ['已结束']],
        'step|1': ['HR初筛', '面试中', '笔试环节', '用人部门筛选'],
      },
    ],
  });
  const { Column } = Table;
  const { Title } = Typography;
  const data = delivery.data;
  return (
    <div className="order">
      <Title level={3}>我的面试</Title>
      <div className="order-table">
        <Table dataSource={data} pagination={{ pageSize: 5 }} rowKey={(record) => record.id}>
          <Column title="面试公司" dataIndex="name" key="id" />
          <Column title="面试岗位" dataIndex="position" key="id" />
          <Column title="面试时间" dataIndex="time" key="id" />
          <Column
            title="状态"
            dataIndex="tags"
            key="tags"
            render={(tags) => (
              <div>
                {tags.map((tag, index) => (
                  <Tag color={`${tag === '进行中' ? 'green' : 'blue'}`} key={tag}>
                    {tag}
                  </Tag>
                ))}
              </div>
            )}
          />
          <Column title="环节" dataIndex="step" key="id" />
          <Column
            title="记录操作"
            key="id"
            render={(tags) => (
              <Space size="middle">
                <Link>简历分析报告</Link>
                <Link>求职规划</Link>
              </Space>
            )}
          />
        </Table>
      </div>
    </div>
  );
};
export default Interview;

import React from 'react';
import './index.less';
import successIcon from './success.svg';
import { memberShipData } from '../common';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { getSearchParam } from 'common/ultils';
const VipSuccessCard = () => {
  const location = useLocation();
  const { activeId = 1 } = getSearchParam(location.search) as { activeId: number };
  return (
    <div className="success-main">
      <div className="title">
        <div className="icon">
          <img src={successIcon} alt="1" />
        </div>
        <p className="p-title-success">支付成功，您已成为优加实习<span style={{color:"#ff4f4c"}}>{memberShipData.filter((i)=>{return i.id===activeId})[0].title}</span></p>
        <p className="member-tips">恭喜解锁会员福利，赶快领取吧！</p>
        <div>
          <img
            className="member-img"
            src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/IMG_0001 3.jpg"
            alt="1"
          />
        </div>
        <p className="number-code-tips">
          扫码添加小助手，即可进入会员专享求职社群，还可领取导师辅导 20 元优惠券哦
        </p>
        <div className="member-desc">
          <div className="vip-content-left-bottom">
            {activeId !== 4 ? (
              <div className="vip-icon-container">
                {memberShipData[activeId - 1].content.map((item) => {
                  return (
                    <div
                      className={classnames('kind', {
                        opcity: activeId !== 1 && item.index > 7,
                      })}
                      key={item.index}
                    >
                      <div className="div-img">
                        <img
                          src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${item.index}.png`}
                          alt="1"
                        />
                      </div>
                      <p>{item.text}</p>
                      <span>{item.icon}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="svip-container">
                <div className="left">
                  <img
                    src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${12}.png`}
                    alt=""
                  />
                </div>
                <div className="and">
                  <img
                    src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${13}.png`}
                    alt=""
                  />
                </div>
                <div className="right">
                  <img
                    src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${14}.png`}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="text-welfare">恭喜你解锁 4 项会员福利，升级终身会员可解锁全部福利～ </p>
      <div className="item">
        <p className="item-p-title"> 会员权益说明</p>
        <p className="item-p-title">
          1. 购买后即刻生效，请在有效期内使用，逾期失效； <br />
          2. 可在我的订单中查看所有购买记录；
          <br />
          3. 成为会员后，即刻享有对应会员特权； <br />
          4. 会员服务如有任何问题，可在工作日联系人工客服解决，人工客服在线时间：10:00-19:00 <br />
          5. 最终解释权归超级简历所有，如有疑问，请联系在线客服
        </p>
      </div>
    </div>
  );
};
export default VipSuccessCard;

import React, { useState } from 'react';
import { Typography, Form, Input,Image, Select, Cascader, message, Button, DatePicker, Avatar } from 'antd';
import { isPhone, isEmail, fromatePrivanceList, saveBaseInfo } from '../../../../../common/ultils';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  commonGetDistrictsUsingGET,
  commonGetDistrictsV2UsingGET,
} from '../../../../../api/common-controller';
import { saveBaseInfoUsingPOST, userBaseInfoUsingGET } from '../../../../../api/user-controller';
import { useMount, useUpdateEffect } from 'ahooks';
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 14 },
  },
};
const { Title } = Typography;
const BaseInfo = () => {
  const [baseInfo, setBaseInfo] = useState({});
  const [privinceList, setPrivinceList] = useState([]);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(true);
  const getPrivinceList = async () => {
    try {
      const { data } = await commonGetDistrictsUsingGET();
      setPrivinceList(fromatePrivanceList(data));
    } catch (e) {
      message('获取城市列表失败');
    }
  };
  const loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    try {
      const { data } = await commonGetDistrictsV2UsingGET({ keyWord: targetOption.value });
      targetOption.children = data.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPrivinceList([...privinceList]);
    } catch (e) {
      message.error(e);
    } finally {
      targetOption.loading = false;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const data = await saveBaseInfoUsingPOST({
        ...values,
        province: values.province[0],
        city: values.province[1],
        birth: values.birth.endOf('d').valueOf(),
        country: '中国',
      });
      if (data) {
        message.success("保存成功")
        setIsEdit(true);
        saveBaseInfo({
          ...values,
          province: values.province[0],
          city: values.province[1],
          birth: values.birth.endOf('d').valueOf(),
          country: '中国',
        });
      }
    } catch (e) {
      message.error(e.error_msg);
    }
  };
  const getBaseInfo = async () => {
    try {
      const { data } = await userBaseInfoUsingGET();
      saveBaseInfo({ ...data });
      setBaseInfo(data);
    } catch (e) {
      message.error(e, '获取用户基础信息失败');
    }
  };
  const init = () => {
    form.setFieldsValue({
      username: baseInfo.username || undefined,
      sex: baseInfo.sex || undefined,
      birth: moment(baseInfo.birth) || undefined,
      mobile: baseInfo.mobile || undefined,
      email: baseInfo.email || undefined,
      province: baseInfo.province ? [baseInfo.province, baseInfo.city] : undefined,
    });
  };
  useMount(() => {
    getPrivinceList();
    init();
    getBaseInfo();
  });
  useUpdateEffect(() => {
    init();
  }, [baseInfo]);
  return (
    <div className="baseInfo">
      <Title level={3}>基本信息</Title>
      <div className="baseInfo-isEdit">
        <Button
          type="text"
          danger
          onClick={() => {
            setIsEdit(false);
          }}
        >
          编辑
        </Button>
      </div>
      <div className="baseInfo-from">
        <Form {...formItemLayout} form={form}>
          <Form.Item label="头像" name="avtor">
          <Avatar
          style={{ backgroundColor: '#87d068', width: 52, height: 52 ,marginLeft:'5px'}}
          icon={<UserOutlined />}
          src={
            <Image
              src="https://joeschmoe.io/api/v1/random"
              preview={false}
              style={{ width: 52 }}
            />
          }
        />
          </Form.Item>
          <Form.Item
            label="姓名"
            name="username"
            rules={[
              {
                required: true,
                message: '请输入姓名！',
              },
            ]}
          >
            <Input placeholder="请填写姓名" disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="性别"
            name="sex"
            rules={[
              {
                required: true,
                message: '请输入性别！',
              },
            ]}
          >
            <Select placeholder="请选择性别 " disabled={isEdit}>
              <Option value={1}>男</Option> <Option value={2}>女</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="生日"
            name="birth"
            rules={[
              {
                required: true,
                message: '请输入生日！',
              },
            ]}
          >
            <DatePicker
              disabled={isEdit}
              disabledDate={(current) => {
                return current > moment().endOf('day');
              }}
            />
          </Form.Item>
          <Form.Item label="电话" name="mobile" required>
            <Input
              disabled={isEdit}
              placeholder="请填写电话"
              rules={[
                {
                  required: true,
                  message: '请输入签约人的手机号！',
                },
                {
                  pattern: isPhone(),
                  message: '请输入正确的手机号！',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              {
                required: true,
                message: '请输入邮箱地址！',
              },
              {
                pattern: isEmail(),
                message: '请输入正确邮箱地址！',
              },
            ]}
          >
            <Input placeholder="请填写邮箱" disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="籍贯"
            name="province"
            rules={[
              {
                required: true,
                message: '请输入籍贯！',
              },
            ]}
          >
            <Cascader
              disabled={isEdit}
              options={privinceList}
              placeholder="请选择籍贯"
              loadData={loadData}
              changeOnSelect
            />
          </Form.Item>
        </Form>
        {!isEdit && (
          <div className="baseInfo-btn">
            <Button type="primary" danger onClick={handleSubmit}>
              保存
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default BaseInfo;

import React from 'react';
import {Typography} from "antd"
const {Link} =Typography

const AnalyseCard = (item) => {
  return (
    <div className="resume-content-right-analyseCard-children"
    >
      <div className="analyseCard-header">
        <img className="analyseCard-header-icon" src={item.analyseCard.icon} alt="true"/>
        <span>{item.analyseCard.title}</span>
        <Link href="#" className="analyseCard-header-btn">立即体验</Link>
      </div>
      <div className="analyseCard-content">
        <img className="analyseCard-content-image" src={item.analyseCard.image} alt="true"/>
        <div className="analyseCard-content-describe">
          <p className="analyseCard-content-describe-state"
            style={{color:`${item.analyseCard.id ===1 ? '#f64':'#4183ff'}`}}
          >{item.analyseCard.state}</p>
          <p className="analyseCard-content-describe-text">{item.analyseCard.content}</p>
        </div>
      </div>
    </div>
  );
};
export default AnalyseCard;
import React, { useEffect, useState, useRef, useContext } from 'react';
import { DisplayContext } from '../../index';
import 'react-chat-elements/dist/main.css';
import { MessageList } from 'react-chat-elements';
import './index.less';
import { notification } from 'antd';
const ChatRoom = () => {
  let { display_sideChat, setdisplay_sideChat,setMenuVisible, delivery } = useContext(DisplayContext);
  const data = JSON.parse(JSON.stringify(delivery.data));
  const [userMsg, setUserMsg] = useState(data);
  const [msg, setMsg] = useState('');
  const inputRef = useRef('');
  let userId = 10;
  const sendMessage = () => {
    const newUser = {
      id: userId,
      title: '我',
      text: inputRef.current.value,
      type: 'text',
      date: new Date(),
      position: 'right',
    };
    setUserMsg(userMsg.concat(newUser));
    userId++;
    setMsg('');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 0);
    });
  };

  // 回车发送消息
  const onEnterDown = async (e) => {
    if(e?.keyCode === 13 && !msg){
      openNotificationWithIcon('warning');
      e.preventDefault();
      return
    }
    if (e?.keyCode === 13 && msg) {
      await sendMessage();
      setMsg('');
    }
    return
  };
  // 空白发言提醒；
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: '请输入发言内容',
      // description:
      //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
  };
  // 保持聊天窗最底部；
  const chatBox = useRef();
  const srcollBottom = () => {
    chatBox.current.scrollTop = chatBox.current.scrollHeight;
  };

  const handlerCloseChatBox = ()=>{
    setdisplay_sideChat('none');
    setMenuVisible(true)
  }
  useEffect(() => {
    srcollBottom();
  });
  useEffect(() => {
    srcollBottom();
  }, [userMsg]);

  return (
    <div className="chat_content">
      <section className="show_chat" style={{ display: display_sideChat }}>
        <div className="chat_tit">
          <img
            src={
              'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/head.png?sign=2b64bbe6c7ab5abe4994cc46b9de1985&t=1650978022'
            }
            alt="true"
          />
          优加实习在线客服
          <span title="最小化" className="btn_min" onClick={handlerCloseChatBox}>
            <img
              src={
                'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/wrong.png?sign=2748ca2c8cd1d9183d2df8615612c522&t=1650978069'
              }
              alt="true"
            />
          </span>
        </div>
        <div id="chatBox" className="chat_box" ref={chatBox}>
          {userMsg.length ? (
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={userMsg}
            />
          ) : (
            <div className="chat_def">暂无消息</div>
          )}
        </div>
        <div className="chat_enter">
          <textarea
            ref={inputRef}
            placeholder="很高兴为您服务，请输入您的问题。"
            className="chat_enter_input"
            value={msg}
            onChange={(e) => {
              setMsg(e.target.value);
            }}
            onKeyDown={(e) => {
              onEnterDown(e);
            }}
          ></textarea>
          <button
            type="button"
            className="chat_enter_btn"
            onClick={() => {
              if (msg) {
                sendMessage();
              } else {
                openNotificationWithIcon('warning');
              }
            }}
          >
            <span>发送</span>
          </button>
        </div>
      </section>
    </div>
  );
};
export default ChatRoom;

import { Divider } from 'antd';
import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import BaseInfo from './components/baseInfo';
import Account from './components/account';
import Order from './components/order'
import './index.less';
import Dilivery from './components/dilivery';
import {useMount} from "ahooks" 
import Interview from './components/interview';

const Personal = () => {
  const [contentkey, setContentKey] = useState(1);
  useMount(()=>{
    window.scrollTo(0, 0);
  })
  const tabContent = [
    {
      text: '基础信息',
      key: 1,
    },
    {
      text: '账号设置',
      key: 2,
    },
    {
      text: '我的订单',
      key: 3,
    },
    {
      text: '投递记录',
      key: 4,
    },
    {
      text: '我的面试',
      key: 5,
    },
  ];
  return (
    <div className="personal">
      <div className="personal-content">
        <div className="personal-content-left">
          {tabContent.map((item) => (
            <Fragment key={item.key}>
              <div className="personalItem" onClick={() => setContentKey(item.key)}>
                <div
                  className={classNames('personalItem-content', {
                    active: item.key === contentkey,
                  })}
                >
                  {item.text}
                </div>
              </div>
              <Divider />
            </Fragment>
          ))}
        </div>
        <div className="personal-content-right">
          {contentkey === 1 && <BaseInfo />}
          {contentkey === 2 && <Account />}
          {contentkey === 3 && <Order />}
          {contentkey === 4 && <Dilivery />}
          {contentkey === 5 && <Interview />}
        </div>
      </div>
    </div>
  );
};
export default Personal;

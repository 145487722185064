import React from 'react';
import './index.less';

const Header = () => {
  return (
    <div className="content_box content_fuwu">
      <ul className="fuwu_card">
        <li>
          <h4>面试辅导</h4>
          <p>资深HR一对一辅导，带您掌握面试规则和技巧！</p>
          <dl>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              <span>60分钟面试辅导</span>
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              预先演练，更好的规避面试差错
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              手把手带你了解行业情况、薪资待遇、面试套路等
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              模拟面试并提出改进建议，让你在面试过程中游刃有余
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              200多位在职CEO、HR在线提供服务
            </dd>
          </dl>
          <div className="btn_get">申请服务</div>
        </li>
        <li>
          <h4>职业规划</h4>
          <p>梳理职业发展方向，制定职业发展计划</p>
          <dl>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              <span>60分钟职业规划咨询</span>
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              理清思路，明确职业定位
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              解答职业发展问题，针对问题给出指导建议
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              了解自身优势，梳理职业发展方向
            </dd>
            <dd>
              <span className="webicon-xuanze">
                <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/right.png?sign=399db109631a19010c6e95763c40fb26&t=1650978210'} alt="true" />
              </span>
              200多位在职CEO、HR在线提供服务
            </dd>
          </dl>
          <div className="btn_get">申请服务</div>
        </li>
      </ul>
    </div>
  )
}

export default Header;


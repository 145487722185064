/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2022-09-05 20:20:11
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-07-31 17:32:49
 * @FilePath: /greenet-resume-project/src/api/user-controller.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import { userBaseInfoResponesType } from './model';

export async function userBaseInfoUsingGET(): Promise<userBaseInfoResponesType> {
  // const headers = Object.create(null);
  const result = await axios.request({
    url: `/api/resume/user/baseInfo`,
    method: 'get',
  });
  return result.data;
}
export async function saveBaseInfoUsingPOST(payload: any) {
  const data = {
    ...payload,
  };
  const headers = Object.create(null);
  headers['Content-Type'] = 'application/json';
  const result = await axios.request({
    url: `/api/resume/user/saveBaseInfo`,
    method: 'post',
    data,
    headers,
  });
  return result.data;
}

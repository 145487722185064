import React, { useState } from 'react';
import './index.less';

const Solve = () => {
  const [title, settitle] = useState(1);

  const tit = {
    title1: 1,
    title2: 2,
    title3: 3,
  };
  const tabs = [
    {
      id: 1,
      title: '简历优化',
      text: '好工作从好简历开始',
      iconActive: 'https://files.wondercv.com/pc/mentor/problem1-active.png',
      icon: 'https://files.wondercv.com/pc/mentor/problem1.png',
    },
    {
      id: 2,
      title: '职业规划',
      text: '走出迷茫，找准职业规划',
      iconActive: 'https://files.wondercv.com/pc/mentor/problem2-active.png',
      icon: 'https://files.wondercv.com/pc/mentor/problem2.png',
    },
    {
      id: 3,
      title: '简历优化',
      text: '好工作从好简历开始',
      iconActive: 'https://files.wondercv.com/pc/mentor/problem3-active.png',
      icon: 'https://files.wondercv.com/pc/mentor/problem3.png',
    },
  ];
  return (
    <div>
      <div className="problem-content pr">
        <div className="content pr">
          <div className="left-tab pr">
            <div className="lefttit">解决三大问题</div>
            {tabs.map((tab) => (
              <div
                className={title === tab.id ? 'tab-cursor Active' : 'tab-cursor '}
                key={tab.id}
                onMouseEnter={() => {
                  settitle(tab.id);
                }}
              >
                {title === tab.id ? (
                  <div className="icon">
                    {' '}
                    <img src={tab.iconActive} alt="true" />
                  </div>
                ) : (
                  <div className="icon">
                    {' '}
                    <img src={tab.icon} alt="true" />
                  </div>
                )}
                <div className="flex1">
                  <div className="flex1-title">{tab.title}</div>
                  <div className="flex1-text">{tab.text}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="pic fr">
            {title === tit.title1 && (
              <img
                alt="true"
                src={'https://files.wondercv.com/pc/mentor/orderNew3_society_pic1.png'}
              />
            )}
            {title === tit.title2 && (
              <img alt="true" src={'https://files.wondercv.com/pc/mentor/threeProblems_2.png'} />
            )}
            {title === tit.title3 && (
              <img alt="true" src={'https://files.wondercv.com/pc/mentor/threeProblems_1.png'} />
            )}
          </div>
        </div>
      </div>
      <div className="mid-indexButton indexButton">立即优化</div>
    </div>
  );
};
export default Solve;

import React,{useState} from 'react';
import { Modal } from 'antd';
import AddNewResume from './addNewResume';
import ImportResume from './importResume'

const ResumeHeader = () => {
  const addIcon = {
    addImg:'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/addFile.png?sign=6be1e592a858f95555bee5b1fb585dce&t=1651334843',
    addImgActive:'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/import-hover.png?sign=f2bf27bd0b541cdfe68d11a6a8f0c98d&t=1652188321'
  }
  const [isActive, setIsActive] = useState(false);
  const [showNewModal,setShowNewModal] = useState(false)
  const [showImportModal,setShowImportModal] = useState(false)
  return (
    <div className="resumeHeader">
      <div className="resumeHeader-btn">
        <button className="resumeHeader-btn-left"
          onClick={()=>setShowNewModal(true)}
        >
          <img
            alt="true"
            src={
              'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/add.png?sign=c29e584beca3ee9380227382b3e35477&t=1651334832'
            }
          />
          <span>新建简历</span>
        </button>
        <Modal
            width={824}
            centered
            children = {<AddNewResume/>}
            open={showNewModal}
            onOk={() => setShowNewModal(false)}
            onCancel={() => setShowNewModal(false)}
          />
        <button className="resumeHeader-btn-right"
            onClick={() => setShowImportModal(true)}
            onMouseEnter={()=>setIsActive(true)} onMouseLeave={()=>setIsActive(false)}
        >
          <img  className="resumeHeader-btn-right-img" alt='true' src={`${isActive? addIcon.addImgActive : addIcon.addImg}`}/>
          <span>导入简历</span>
        </button>
        <Modal
            width={600}
            centered
            children = {<ImportResume/>}
            open={showImportModal}
            onOk={() => setShowImportModal(false)}
            onCancel={() => setShowImportModal(false)}
          />
          {/* {
            showImportModal && <ImportResume/>
          } */}
      </div>
    </div>
  );
};
export default ResumeHeader;

import Header from './components/Header';
import Product from './components/Product/index';
import Solve from './components/Solve/index';
import './index.less';
import React from 'react';
import {useMount} from "ahooks" 
import UsersSwiper from './components/UsersSwiper/index';
import SiderImg from '../../../components/SiderImg/index';

const Modify = () => {
  useMount(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <div className="layouts-content pr">
      <Header />
      <Product />
      <Solve />
      <div className="mid-content2">
        <img
          src="https://files.wondercv.com/PC/mentor/team.png"
          alt="专业导师，熟悉校招&amp;社招全流程"
          className="pic2"
        />
        <div className="text">
          <div className="text-title">资深导师为你定制简历</div>
          <div className="text-in">
            <p>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/right2.png?sign=b24038c2b29424798dfd536fe5159d60&t=1648876941'
                }
                alt="true"
              />
              &nbsp;&nbsp;100+资深HRD & 大厂业务负责人
            </p>
            <p>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/right2.png?sign=b24038c2b29424798dfd536fe5159d60&t=1648876941'
                }
                alt="true"
              />
              &nbsp;&nbsp;导师执笔、多重质检、质量保证
            </p>
            <p>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/right2.png?sign=b24038c2b29424798dfd536fe5159d60&t=1648876941'
                }
                alt="true"
              />
              &nbsp;&nbsp;根据岗位定制，充分展现个人优势
            </p>
            <p>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/right2.png?sign=b24038c2b29424798dfd536fe5159d60&t=1648876941'
                }
                alt="true"
              />
              &nbsp;&nbsp;经历挖掘，能力提炼，框架充足，成果展现
            </p>
            <p>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/right2.png?sign=b24038c2b29424798dfd536fe5159d60&t=1648876941'
                }
                alt="true"
              />
              &nbsp;&nbsp;专属服务群，高效沟通省时省力
            </p>
          </div>
          <div className="indexButton">立即优化</div>
        </div>
      </div>
      <div className="mid-content3">
        <div className="mid-content3-title">服务流程，质量保障</div>
        <img src="https://files.wondercv.com/pc/mentor/orderNew5-new.png" alt="简历优化流程" />
      </div>
   
      <UsersSwiper />
      <SiderImg />
    </div>
  );
};
export default Modify;

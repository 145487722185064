import { Tag } from 'antd';
import React from 'react';

const ResumeCard = (data) => {
  const choose = [
    {
      id:1,
      name:'下载简历'
    },
    {
      id:2,
      name:'修改简历'
    },
    {
      id:3,
      name:'删除简历'
    }
  ]
  const datas = data.cardData;
  return (
    <div className="resumeCard">
      <img className="resumeCard-fileImg" alt="true"
      src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/pdf.png?sign=341c0c75225efda59b8f755c17dbbf02&t=1652180205'} />
      <>
      <div className="resumeCard-on">
        <span className="resumeCard-on-name">{datas.name}</span>
      </div>
      <div className="resumeCard-down">
        <Tag
          className="resumeCard-down-score"
          color={`${datas.resumeScore >= 60 ? 'green' : 'volcano'}`}
        >
          {`${datas.resumeScore}分`}
        </Tag>
        <Tag className="resumeCard-down-language" color="blue">
          {datas.language}
        </Tag>
        <span className="resumeCard-down-time">{`最后编辑于：${datas.time}`}</span>
      </div>
      </>
      <div className="resumeCard-choose">
        {choose.map(item=>{
          return(
            <a key={item.id} href="/#">
              <img alt="true"
              src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/download.png?sign=47fd16f57237f84af5d73082221054ac&t=1652186303'}
              />
              <span>{item.name}</span>
            </a>
          )
        })}
      </div>
    </div>
  );
};
export default ResumeCard;

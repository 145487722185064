/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2023-08-06 18:28:03
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-09 21:32:27
 * @FilePath: /greenet-resume-project/src/App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import logo from './logo.svg';
import { ConfigProvider, Spin } from 'antd';
import React from 'react';
import Home, { HomeRoutes } from './pages/home';
import HomeMobile from './pages/mobile';
import { isMobile } from './common/ultils';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import AllianceLayout from './componets/Layout';
import { useDispatch } from 'react-redux';
import { asyncFetchBaseUserInfo, asyncFetchQueryAccount } from './reduxStroe/global';
import './App.css';
const App = () => {
  const isRecruitInfo = window.location.href;

  const dispatch = useDispatch();

  dispatch(asyncFetchBaseUserInfo({}));
  dispatch(asyncFetchQueryAccount({}));
  return (
    <ConfigProvider
      locale={zh_CN}
      getPopupContainer={(node) => {
        if (node && node.parentNode) {
          return node.parentNode;
        }
        return document.body;
      }}
    >
      {
        <Router>
          <Switch>
            {!isRecruitInfo.includes('recruitInfo') && isMobile() ? (
              <Route
                exact
                path={HomeRoutes}
                component={() => (
                  <React.Suspense fallback={<Spin tip="页面加载中..." />}>
                    <HomeMobile />
                  </React.Suspense>
                )}
              />
            ) : (
              <Route exact path={HomeRoutes} component={Home} />
            )}

            <AllianceLayout />
          </Switch>
        </Router>
      }
    </ConfigProvider>
  );
};

export default App;

import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { ChoseTypeData } from 'common/contant';
import './index.less';
import { useUnmount,useMount } from 'ahooks';

const ChoseType = () => {
  const [btnId, setbtnId] = useState(1);
  const history = useHistory();
  useMount(()=>{
    window.scrollTo(0, 0);
  })
  useUnmount(() => {
    localStorage.removeItem('order');
  });

  return (
    <div className="mid-content4" id="mid-content4-box">
      <div className="mid-content4-title">请选择你的服务类型</div>
      <div className="mid-content4-card">
        {ChoseTypeData.map((card) => (
          <div
            className="lists"
            key={card.type}
            style={{
              height: `${btnId === card.type ? '551px' : '493px'}`,
              transition: 'height 0.2s',
              transitionTimingFunction: 'ease-out',
            }}
            onMouseEnter={() => {
              setbtnId(card.type);
            }}
          >
            <div className="content">
              <div className="lottie-anima">
                <Lottie options={card.avatar} width={120} />
              </div>
              <h6 className="head">
                {card.title}
                <div className="money">¥</div>
                <div className="num">{card.num}</div>
              </h6>
              <p className="listcontent">
                {card.content1}
                <br />
                {card.content2}
              </p>
              <img className="advantage" src={card.img} alt="true" />
            </div>
            {btnId === card.type ? (
              <button
                className="list-buttons"
                style={{
                  visibility: 'visible',
                  height: `${btnId === card.type && '52px'}`,
                  transition: 'all 0.5s',
                  transitionTimingFunction: 'ease-out',
                }}
                onClick={() => {
                  history.push(
                    `./serviceSelection/?type=${card.type}&order=${
                      localStorage.getItem('order') ?? '1'
                    }`,
                  );
                }}
              >
                立即优化
              </button>
            ) : (
              <div className="empty-button"></div>
            )}
            <p className="list-havebuy">{card.hadBuy}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChoseType;

/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2023-02-23 10:42:46
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-07-28 11:12:26
 * @FilePath: /greenet-resume-project/src/common/contant.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import book3 from 'common/lottie/book3.json';
import send from 'common/lottie/send.json';
import management from 'common/lottie/management.json';
export const personalData = [
  {
    icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/icon_wrxcjppmjsh/shezhi.png?sign=748a0301f026cf22f9666511e19db957&t=1649345903',
    id: 1,
    title: '账户设置',
    link: '/personalCenter',
  },
  {
    icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/icon_wrxcjppmjsh/huiyuan.png?sign=1643dd38cdce1d4579c370ebe01751d1&t=1649345932',
    id: 2,
    title: '超级会员',
    link: '/superVip',
  },
  {
    icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/icon_wrxcjppmjsh/dingdan.png?sign=c0956cef189d52e3245a41a67482e29f&t=1649345943',
    id: 3,
    title: '我的订单',
    link: '/order',
  },
  {
    icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/icon_wrxcjppmjsh/kefu.png?sign=962e9ab9ed05f10f0affcf34377c83dc&t=1649345923',
    id: 4,
    title: '问题反馈',
    link: '',
  },
  {
    icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/icon_wrxcjppmjsh/zaizhaorenshu.png?sign=620bc29d8161cb9918bd6617a49d1b16&t=1649345915',
    id: 5,
    title: '我要招人',
    link: '',
  },
  // {
  //   icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/icon_wrxcjppmjsh/%E9%80%80%E5%87%BA.png?sign=8e5d185ab6f4b6134adecffc51c76555&t=1649347908',
  //   id: 6,
  //   title: '退出',
  //   link: '',
  // },
];
export const ChoseTypeData = [
  {
    type: 1,
    title: '实习版',
    num: 159,
    content1: '适用于求职实习机会的在校生',
    content2: '7 天帮你搞定实习',
    avatar: {
      autoplay: true,
      loop: true,
      animationData: book3,
    },
    img: 'https://files.wondercv.com/PC/mentor/intern_service.png',
    hadBuy: '3,498 人已购买服务',
  },
  {
    type: 2,
    title: '校招版',
    num: 249,
    content1: '适用于投递校招的应届生',
    content2: '好评率98.4%，秒变Offer收割机',
    avatar: {
      autoplay: true,
      loop: true,
      animationData: send,
    },
    img: 'https://files.wondercv.com/PC/mentor/college_service.png',
    hadBuy: '6,582 人已购买服务',
  },
  {
    type: 3,
    title: '在职版',
    num: 299,
    content1: '适用已有工作经验的职场人',
    content2: '升职、跳槽、加薪必选',
    avatar: {
      autoplay: true,
      loop: true,
      animationData: management,
    },
    img: 'https://files.wondercv.com/PC/mentor/working_service.png',
    hadBuy: '3,539 人已购买服务',
  },
];

import React, { useState, Fragment } from 'react';
import { Avatar, Image, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { personalData } from '../../../common/contant';
import Login from '../../../componets/Login';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useMount, useUpdate } from 'ahooks';
import Cookies from 'js-cookie';
import new1 from 'common/NEW.png';
import { asyncFetchBaseUserInfo, asyncFetchQueryAccount } from '../../../reduxStroe/global';
import './index.less';
import { useSelector } from 'react-redux';
const blackHeaderList = ['/home/search', '/home/detail'];

const Header = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const [hasBG, setHasBG] = useState(false);
  const update = useUpdate();
  const { userBaseInfo, queryAccount } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [isVisiable, setIsVisiable] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const islogin = () => {
    if (Cookies.get('xxl_sso_sessionid')) {
      setIsLogin(true);
      setIsVisiable(false);
      return;
    }
    setIsVisiable(true);
    !isVisiable && !isLogin && openLogin();
  };
  const openLogin = () => {
    setTimeout(() => {
      !isLogin && setIsVisiable(true);
    }, 5000);
  };

  const menuFaction = (link) => {
    Cookies.remove('xxl_sso_sessionid');
    setIsVisiable(true);
    setIsLogin(false);
    update();
    history.push(link);
  };

  useMount(() => {
    islogin();
    const home = document.querySelector('.home');
    home.scrollTop = 0;
    const handleScroll = (e) => {
      setHasBG(e.target.scrollTop > 5 || blackHeaderList.some((item) => pathname.startsWith(item)));
    };
    home?.addEventListener('scroll', handleScroll);
    return () => {
      home?.removeEventListener('scroll', handleScroll);
    };
  });

  const Profile = (
    <div>
      <img
        alt="1"
        className="profile-bg"
        src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/profile-bg.png"
      />
      <div className="header-profile-top">
        <div className="self-info-name">
          <Avatar
            style={{ width: 48, height: 48 }}
            icon={<UserOutlined />}
            src={
              <Image
                src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/avatar.png"
                preview={false}
                style={{ width: 48 }}
              />
            }
          />
          <div className="name-desc">
            <div className="name">
              <a className="name-link" href="./">
                {userBaseInfo?.username ?? '优加DA-0000用户'}
              </a>
              <img
                alt="1"
                src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/icon-1.png"
                className="name-img"
              />
            </div>
            <div className="identify">
              <div className="hove-to-green">
                暂未认证，去认证
                <span className="nc-icon">
                  <svg
                    focusable="false"
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    width="12"
                    height="12"
                    aria-hidden="true"
                    data-v-79ba69ea=""
                  >
                    <path
                      fill="currentColor"
                      fill-rule="nonzero"
                      d="M289.3514 187.8043c-19.8576-17.5625-21.7181-47.8974-4.1557-67.755 17.3869-19.659 47.292-21.679 67.1561-4.6767l.5989.521L723.848 443.9236c33.096 29.2708 36.1968 79.829 6.926 112.9249a80 80 0 0 1-6.115 6.1989l-.811.7271-370.8973 328.0299c-19.8576 17.5624-50.1925 15.7019-67.755-4.1557-17.3868-19.659-15.7371-49.5868 3.5654-67.2242l.5903-.5307L646.696 503.85 289.3514 187.8043z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <a className="self-page" href="./">
          个人主页
        </a>
      </div>
      <div className="ling-lone"></div>
      <div className="vip-card">
        <img
          className="vip-card-bg"
          alt="1"
          src={
            !!queryAccount?.vipType
              ? 'https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/vip-bg.png'
              : 'https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/vip-bg.png'
          }
        />
        <div className="vip-text-label">
          <div className={classnames('yj-vip-text', { vip: !!queryAccount?.vipType })}>
            优加会员
          </div>
          <img
            alt="1"
            className="vip-label-pic"
            src={
              !!queryAccount?.vipType
                ? 'https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/vip-icon.png'
                : 'https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/pre-vip.png'
            }
          />
        </div>
        <div className="yj-vip-desc">
          <a className="yj-vip-desc" href="./">
            {!!queryAccount?.vipType
              ? `会员到期时间：${moment(queryAccount?.endTime).format('YYYY-MM-DD')}`
              : '实习路上优加为您保驾护航'}
          </a>
        </div>
        <div className="open-vip-btn">
          <a
            className="open-vip-btn-text"
            href="./"
            onClick={(e) => {
              history.push('./superVip');
              e.preventDefault();
            }}
          >
            {!!queryAccount?.vipType ? '立即续费' : '立即开通'}
          </a>
        </div>
      </div>
      <div className="nc-nav-header-link">
        {personalData.map((item) => {
          return (
            <div
              style={{ display: 'inline-block' }}
              onClick={() => {
                history.push(item.link);
              }}
            >
              <div className="nc-nav-header-link-item" key={item.id}>
                <img className="nc-nav-header-link-item-icon" alt="1" src={item.icon} />
                <span className="nc-nav-header-link-item-text">{item.title}</span>
              </div>{' '}
            </div>
          );
        })}
      </div>
      <div className="nc-nav-header-link">
        <div className="nc-nav-header-link-line"></div>
        <div className="account-set">
          <div className="account-set-item" onClick={() => history.push('/personalCenter')}>
            账户设置
          </div>
          <div className="account-set-middle"></div>
          <div
            className="account-set-item"
            onClick={() => {
              menuFaction('/');
            }}
          >
            退出登录
          </div>
        </div>
      </div>
    </div>
  );
  const vipCard = (
    <div>
      <div className="title-line-1">优加会员·轻松斩OFFER</div>
      <div className="title-line-2"> 实习路上优加为您保驾护航</div>
      <div className="vip-desc-card-warpper">
        <div className="privilege-card">
          <img
            className="privilege-card-icon"
            alt="1"
            src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/privilege-video.png"
          />
          <span className="privilege-card-text">视频题解100+</span>
        </div>
        <div className="privilege-card">
          <img
            className="privilege-card-icon"
            alt="1"
            src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/privilege-vip.png"
          />
          <span className="privilege-card-text">会员专属标签</span>
        </div>
        <div className="privilege-card">
          <img
            className="privilege-card-icon"
            alt="1"
            src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/privilege-v.png"
          />
          <span className="privilege-card-text">简历AI诊断</span>s
        </div>
      </div>
      <div className="vips-btn">
        <div className="learn-vip-btn-bg">
          <a href="./" className="learn-vip-btn-bg-a">
            <span className="learn-vip-btn-bg-a-span">了解会员</span>
          </a>
        </div>
        <div className="open-vip-btn" onClick={() => history.push('./superVip')}>
          <span>成为会员</span>
        </div>
      </div>
    </div>
  );
  return (
    <Fragment>
      <header className={classnames('header', { hasBG })}>
        <div className="header-box">
          <img
            src="https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/333-removebg-preview.png?sign=a9f1feb7ad55a61912621f0b16532b44&t=1648814095"
            alt="优加实习"
            onClick={() => history.push('/')}
            className="logo"
          />
          <nav className="header-items">
            <div className="header-items-middle">
              {HeaderItems.map((item, index) => (
                <div
                  key={item.link}
                  className={classnames('header-item', {
                    active: item.link === pathname,
                  })}
                  onClick={() => {
                    history.push(item.link);
                  }}
                >
                  {/* {item.link === '/resume' && (
                    <div className="lottie-anima-new">
                      <Lottie options={defaultOptions1} width={50} />
                    </div>
                  )} */}
                  <span className={classnames('node', { active: item.link === pathname })} />
                  {item.text}
                  {index === 2 && <img src={new1} alt="1"></img>}
                </div>
              ))}
            </div>

            {isLogin ? (
              <div className="header-right">
                <Dropdown
                  overlay={Profile}
                  overlayClassName="dropdown"
                  placement="bottom"
                  onVisibleChange={(isOpen) => {
                    if (isOpen) {
                      dispatch(asyncFetchBaseUserInfo({}));
                      dispatch(asyncFetchQueryAccount({}));
                    }
                  }}
                >
                  <div className="profile-center">
                    <Avatar
                      style={{ width: 20, height: 20 }}
                      icon={<UserOutlined />}
                      src={
                        <Image
                          src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/avatar.png"
                          preview={false}
                          style={{ width: 20 }}
                        />
                      }
                    />
                    <span className="vip-menu-text">个人中心</span>{' '}
                  </div>
                </Dropdown>
                {!queryAccount?.vipType && (
                  <Dropdown
                    overlay={vipCard}
                    overlayClassName="vip-menu-dropdown"
                    placement="bottom"
                    onVisibleChange={(isOpen) => {
                      if (isOpen) {
                        dispatch(asyncFetchQueryAccount({}));
                      }
                    }}
                  >
                    <div
                      className="vip-menu"
                      onClick={async () => {
                        // history.push('/make');
                      }}
                    >
                      <div
                        className="vip-menu-icon"
                        onClick={() => {
                          history.push('./superVip');
                        }}
                      >
                        <Avatar
                          style={{ width: 20, height: 20 }}
                          icon={<UserOutlined />}
                          src={
                            <Image
                              src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/vip-icon.png"
                              preview={false}
                              style={{ width: 20 }}
                            />
                          }
                        />
                      </div>
                      <span className="vip-menu-text">会员</span>
                    </div>
                  </Dropdown>
                )}
              </div>
            ) : (
              <>
                <div
                  className="login"
                  onClick={() => {
                    islogin();
                  }}
                >
                  <span className="text">登录</span>
                  <div className="animation" />
                </div>{' '}
              </>
            )}
          </nav>
        </div>
      </header>
      <Login isVisiable={isVisiable} setIsVisiable={setIsVisiable} setIsLogin={setIsLogin} />
    </Fragment>
  );
};
export default Header;
export const HeaderItems = [
  {
    text: '优加简介',
    link: '/',
  },
  // {
  //   text: '我的简历',
  //   link: '/resume',
  // },
  {
    text: '简历模版',
    link: '/interviews',
  },
  {
    text: '简历修改',
    link: '/modify',
  },
  {
    text: '模拟面试',
    link: '/interviews',
  },
  {
    text: '精品课程',
    link: '/interviews',
  },
  //   {
  //     text: '内推投递',
  //     link: '/recruitInfo',
  //   },
];

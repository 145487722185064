import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col,Typography } from 'antd';
import 'antd/dist/antd.css';
import './index.less';
import img from './img1.jpg';
import img1 from './IMG_5402 2.jpg';
import img2 from './IMG_5509.jpg';
import { getChildrenToRender, isImg } from '../../common/data';

const {  Link } = Typography;
class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1',
  };

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' && title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>{childWrapper.children.map(getChildrenToRender)}</div>
        </Col>
      );
    });

  render() {
    const block = {
      className: 'footer',
      gutter: 0,
      children: [
        {
          name: 'block0',
          xs: 24,
          md: 6,
          className: 'block',
          title: {
            className: 'logo',
            children:
              'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/333-removebg-preview.png?sign=a9f1feb7ad55a61912621f0b16532b44&t=1648814095',
          },
          childWrapper: {
            className: 'slogan',
            children: [
              {
                name: 'content0',
                children: 'Created by Greenet-TC&&Greenet-PM',
              },
            ],
          },
        },
        {
          name: 'block1',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: '产品' },
          childWrapper: {
            children: [
              { name: 'link0', href: '#', children: '优加实习' },
              { name: 'link1', href: '#', children: '优加小程序' },
            ],
          },
        },
        {
          name: 'block2',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: '关于' },
          childWrapper: {
            children: [
              { href: '#', name: 'link0', children: 'FAQ' },
              { href: '#', name: 'link1', children: '联系我们' },
            ],
          },
        },
        {
          name: 'block3',
          xs: 24,
          md: 6,
          className: 'block',
          title: { children: '资源' },
          childWrapper: {
            children: [
              { href: 'https://greenet.org.cn', name: 'link0', children: '优加实习资料' },
              {
                href: 'https://youjiahsixiblog-8gkcqiire449d547-1302287111.tcloudbaseapp.com/',
                name: 'link1',
                children: '优加面经',
              },
            ],
          },
        },
      ],
    };
    const copyright = {
      className: 'copyright',
      children: (
        <span>
          www.youjiasx.com©2021.09 Created by Greenet{' '}
    <Link href="https://beian.miit.gov.cn" target="_blank">
      鄂ICP备2023023511号-1
    </Link>
        
        </span>
      ),
    };
    const QrCodeUrl = [
      {
        imgUrl: img,
        text: '公众号',
      },
      {
        imgUrl: img1,
        text: '视频号',
      },
      {
        imgUrl: img2,
        text: '信息共享',
      },
      {
        imgUrl: 'https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/IMG_0001 3.jpg',
        text: '🎁 小助手 🎁',
      },
    ];
    const childrenToRender = this.getLiChildren(block.children);
    return (
      <div className="footer-wrapper footer1-wrapper">
        <OverPack className="footer1" playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            componentProps={{ gutter: 0 }}
            className="footer"
          >
            {childrenToRender}
            <div className="flooter-img-box">
              {QrCodeUrl.map((i, index) => {
                return (
                  <div className="flooter-img-box-item" key={index}>
                    <img src={i.imgUrl} alt="" />
                    <span>{i.text}</span>
                  </div>
                );
              })}
            </div>
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            className="copyright-wrapper"
          >
            <div className="footer">
              <div className="copyright">{copyright.children}</div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;

import { changeBaseInfoAction } from '../reduxStroe/store/action/actionCreator';
import store from '../reduxStroe/store';

export const saveToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = (key) => {
  let item = sessionStorage.getItem(key) || '';
  let result;
  try {
    result = JSON.parse(item);
  } catch (e) {
    result = null;
  }
  return result;
};
export function isPhone() {
  return new RegExp(
    '^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\\d{8}$',
  );
}
// 将 location.search 转为 object
export const getSearchParam = (search) => {
  const params = search.slice(1).split('&');
  const result = {};
  params.forEach((item) => {
    const arr = item.split('=');
    [, result[arr[0]]] = arr;
  });    
  return result;
};
export function isEmail() {
  return new RegExp('^[a-zA-Z0-9_-_.]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$');
}
export const saveBaseInfo = (record) => {
  const action = changeBaseInfoAction(record);
  store.dispatch(action);
};
export const fromatePrivanceList = (data) => {
  return data.map((item) => {
    return {
      value: item.name,
      label: item.name,
      isLeaf: false,
      children: item.children?.map((e) => {
        return { value: e.name, label: e.name };
      }),
    };
  });
};
export const isMobile = () => {
  return !!navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  );
};

export const getScroll = (id) => {
  if (!id) return;
  document.getElementById(id).scrollIntoView({
    behavior: 'smooth', // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
    block: 'center', // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
    inline: 'nearest', // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
  });
};

/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2022-09-05 20:20:11
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-07-27 16:28:25
 * @FilePath: /greenet-resume-project/src/pages/home/pages/personal/components/order.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import { Table, Tag, Space, Typography } from 'antd';
import Mock from 'mockjs';
import { WechatPayControllerQueryPayStatusPOST } from '../../../../../api/wei-xin-pay-controller';
import message from 'common/message';
import { useMount } from 'ahooks';
const { Link } = Typography;
const Order = () => {
  const data2 = Mock.mock({
    'data|1-10': [
      {
        'id|+1': 1,
        'orderName|1': ['导师辅导-实习版', '导师辅导-校招版', '导师辅导-在职版'],
        'orderId|10000000000-100000000000': 10000000000,
        'tags|1': [['未付款'], ['修改中'], ['取消订单'], ['修改完成']],
      },
    ],
  });
  const getQueryList = async () => {
    try {
      const res = await WechatPayControllerQueryPayStatusPOST();
      console.log(res);
    } catch (e) {
      message.error(e.error_msg, '获取订单错误');
    }
  };
  useMount(() => {
    getQueryList();
  });
  const { Column } = Table;
  const { Title } = Typography;
  const [data, setdata] = useState(data2.data);
  return (
    <div className="order">
      <Title level={3}>我的订单</Title>
      <div className="order-table">
        <Table dataSource={data} pagination={{ pageSize: 14 }} rowKey={(record) => record.id}>
          <Column title="订单名称" dataIndex="orderName" key="orderName" />
          <Column title="订单号" dataIndex="orderId" key="id" />
          <Column
            title="订单进度"
            dataIndex="tags"
            key="tags"
            render={(tags) => (
              <div>
                {tags.map((tag, index) => (
                  <Tag
                    color={`${
                      tag === '取消订单'
                        ? 'gray'
                        : tag === '未付款'
                        ? 'red'
                        : tag === '修改中'
                        ? 'blue'
                        : 'green'
                    }`}
                    key={tag}
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            )}
          />
          <Column
            title="订单操作"
            key="id"
            render={(tags) => (
              <Space size="middle">
                {tags.tag === '未付款' ? (
                  <Link href="#">去付款 </Link>
                ) : (
                  <Link style={{ color: 'gray', pointerEvents: 'none', cursor: 'default' }}>
                    去付款{' '}
                  </Link>
                )}
                <Link
                  onClick={() => {
                    setdata(data2.data.splice(tags.id, 1));
                  }}
                >
                  删除订单
                </Link>
              </Space>
            )}
          />
        </Table>
      </div>
    </div>
  );
};
export default Order;

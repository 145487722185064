import axios from 'axios';

export async function commonGetDistrictsUsingGET() {
  // const headers = Object.create(null);
  const result = await axios.request({
    url: `/api/resume/common/getDistricts`,
    method: 'get',
  });
  return result.data;
}
export async function commonGetDistrictsV2UsingGET(params) {
  // const headers = Object.create(null);
  const result = await axios.request({
    url: `/api/resume/common/getDistricts`,
    method: 'get',
    params,
  });
  return result.data;
}

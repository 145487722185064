import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-mobile-wrapper jzih1dpqqrg-editor_css' },
  page: { className: 'home-page-mobile' },
  logo: {
    className: 'header3-logo jzjgnya1gmn-editor_css',
    children:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/7777-removebg-preview.png?sign=ab767de813437c4a597b1e08ff2c37a7&t=1648814629',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#',
          children: [{ children: <p>订订群</p>, name: 'text' }],
        },
        subItem: [
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item jzj8295azrs-editor_css',
              children: [
                {
                  name: 'image0',
                  className: 'item-image jzj81c9wabh-editor_css',
                  children:
                    'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*4_S6ToPfj-QAAAAAAAAAAABkARQnAQ',
                },
              ],
            },
            name: 'sub~jzj8hceysgj',
          },
        ],
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#',
          children: [{ children: <p>帮助中心</p>, name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-mobile-wrapper banner5' },
  page: { className: 'home-page-mobile banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '优加实习', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '轻松斩获实习offer',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '路漫漫其修远兮，吾将上下求实习',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-mobile-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page-mobile feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: '服务指标' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '人' },
              toText: true,
              children: '1116',
            },
            children: { className: 'feature6-text', children: '累计使用人数' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '万' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: '累计访问量' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'pv' },
              toText: true,
              children: '432',
            },
            children: { className: 'feature6-text', children: '日活跃量' },
          },
        ],
      },
      {
        title: { className: 'feature6-title-text', children: '资源分布' },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '人' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: '简历修改导师' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '人' },
              toText: true,
              children: '17',
            },
            children: { className: 'feature6-text', children: '模拟面试导师' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '人' },
              toText: true,
              children: '2101',
            },
            children: { className: 'feature6-text', children: '内推成功人数' },
          },
        ],
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-mobile-wrapper feature7-wrapper' },
  page: { className: 'home-page-mobile feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: '提供在线服务',
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: '您可以轻松找到实习',
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/jiaoyu.png?sign=699b3908be07803a6ec615f00d69511d&t=1652706361',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '简历模版',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '根据精美的简历模板，在线编辑、保存、导出自己的简历模板。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/gongzuotai.png?sign=47133ab51e9e9b6eadbccd9b90745d12&t=1652706333',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '简历修改',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '不知道简历内容怎么写？我们拥有专业的简历修改导师团队，给您提供简历精修服务，提高简历筛选通过率',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/xiangmu.png?sign=a4e8598fb46566f216e5c60e1129b43f&t=1652706966',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '模拟面试',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '担心面试通不过？我们提供专业的模拟面试团队，模拟最真实的实习面试流程，提供最新面试问题，根据不同的岗位，分配不同的导师，提高面试通过率',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/xiangmujingyan.png?sign=3e490c29fd333ab2198bf6ae42f41d85&t=1652706984',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '实习内推',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '通过AI智能分析你的简历内容，给您匹配最合适的实习岗位，专业的培训团队，内推成功率95%，让你的offer触手可及',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
              'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/gongzuoliushezhi.png?sign=f47dbf5142d1b7d150755fb439da564a&t=1652707190',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '实习租房',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '实习短租怕被骗？流程太复杂？优加实习提供一站式服务，给您解决实习租房问题，并且不收取任何中间费用。',
            },
          ],
        },
      },
      
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-mobile-wrapper content0-wrapper' },
  page: { className: 'home-page-mobile content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'jzjn8afnsxb-editor_css content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item jzjgrrupf2c-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjgrlz134-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzj8xt5kgv7-editor_css',
              children: '一站式业务接入',
            },
            {
              name: 'content',
              children: '支付、结算、核算接入产品效率翻四倍',
              className: 'jzj8z9sya9-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjncn210ql-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzjne54fwqm-editor_css',
              children: '一站式事中风险监控',
            },
            {
              name: 'content',
              children: '在所有需求配置环节事前风险控制和质量控制能力',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjndq0dueg-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzjne24af8c-editor_css',
              children: '一站式数据运营',
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
      {
        name: 'block~jzjn87bmyc7',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjndsyw8sf-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzjndw5oerk-editor_css',
              children: '一站式数据运营',
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-mobile-wrapper feature8-wrapper' },
  page: { className: 'home-page-mobile feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '使用流程' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: '#', children: '立即体验' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '智能简历编辑平台',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://open.weixin.qq.com/zh_CN/htmledition/res/assets/res-design-download/icon48_appwx_logo.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '关注优加实习公众号',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '关注公众号回复关键词【实习】，获取实习资源大礼包',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://i-1-lanrentuku.52tup.com/2020/11/9/cc29aa3e-ebdc-4260-a16c-92e1a9f99f51.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '登陆优加实习官网',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '根据自己的条件选择适合自己的精美模版，编辑内容',
                  },
                ],
              },
            },  {
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child1',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/addFile.png?sign=6be1e592a858f95555bee5b1fb585dce&t=1651334843',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '智能简历解析',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '如果您已有简历pdf，可以点击【简历导入】，会对已有简历解析自动填充道平台，灵活、便捷、高效',
                    },
                  ],
                },
              },  {
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child1',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://i-1-lanrentuku.52tup.com/2020/11/9/cc29aa3e-ebdc-4260-a16c-92e1a9f99f51.png',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '智能简历评估',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '点击【简历分析报告】平台通过AI分析，智能生成您的简历评估结果，包括：简历整体评估分析和建议、岗位智能匹配、薪资评估等',
                    },
                  ],
                },
              },{
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child2',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/plan-icon.png?sign=7336a096b6334b01eebb8edf35163cf9&t=1652169869',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '职业规划',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '点击【职业规划】，平台会有简历为您推荐更适合的职业规划建议和预测',
                    },
                  ],
                },
              },
          
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: '一站式服务平台',
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child2',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor1.png?sign=bd80ba7f12bccb47149886b4180354cf&t=1651840629',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '职业规划',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '点击【简历指导】，平台会有简历修改导师与您联系，定制您的简历',
                    },
                  ],
                },
              },
              {
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child3',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor2.png?sign=82b5656165ff204313f2600acf9e3c04&t=1651840653',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '模拟面试',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '点击【模拟面试】，导师稍后会你联系，对面试进行指导、培训、反馈',
                    },
                  ],
                },
              },
              {
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child3',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor3.png?sign=e39bf9b81390931cb9b320cad47f8502&t=1651840763',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '实习内推',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '点击【实习内推】，本平台目前和100+公司达成战略合作，您可以一键投递相应的岗位，简历通过率高',
                    },
                  ],
                },
              }, {
                className: 'feature8-block-col',
                md: 6,
                xs: 24,
                name: 'child3',
                arrow: {
                  className: 'feature8-block-arrow',
                  children:
                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                },
                children: {
                  className: 'feature8-block-child',
                  children: [
                    {
                      name: 'image',
                      className: 'feature8-block-image',
                      children:
                        'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor4.png?sign=655adb30a90294910bbe98a79ae3e31b&t=1651840664',
                    },
                    {
                      name: 'title',
                      className: 'feature8-block-title',
                      children: '实习租房',
                    },
                    {
                      name: 'content',
                      className: 'feature8-block-content',
                      children:
                        '点击【实习租房】，会有租房小哥和您联系。本平台目前提供免费的租房服务。',
                    },
                  ],
                },
              },
          ],
        },
      ],
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-mobile-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page-mobile',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo jzl0qcpyjra-editor_css',
          children:
            'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/333-removebg-preview.png?sign=a9f1feb7ad55a61912621f0b16532b44&t=1648814095',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            { name: 'content0', children: <p>Greenet团队提供技术支持</p> },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: <p>微信公众号</p> },
        childWrapper: {
          children: [
            {
              name: 'image~jzl0tcm4f1d',
              className: 'image-jzl0tcm4f1d',
              children:
                'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/IMG_5152.JPG?sign=83b02e37bbf282c1d7ecdc379b48012a&t=1656600690',
            },
           
          ],
        },
      },
     
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page-mobile' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <a href="https://greenet.org.cn/">隐私权政策</a>&nbsp; &nbsp; &nbsp;
        |&nbsp; &nbsp; &nbsp; <a href="http://abc.alipay.com">权益保障承诺书</a>&nbsp;
        &nbsp; &nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2022007030号-1</a>&nbsp; &nbsp;
        &nbsp;&nbsp;Copyright © 2022 优加实习科技有限公司<br />
      </span>
    ),
  },
};

import React from 'react';
import './index.less';

const Process = () => {
  return (
    <div className="content_box content_bg">
      <h3>服务流程</h3>
      <ul className="liucheng_list">
        <li>
          <h4>1、填写信息</h4>
          <p>填写工作年限和订单信息。</p>
          <span>
            <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/qianjin.png?sign=a3a56e63463e3e88ebbc6e046fe53381&t=1650978148'} alt="true" />
          </span>
        </li>
        <li>
          <h4>2、下单支付</h4>
          <p>下单并支付成功。</p>
          <span>
            <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/qianjin.png?sign=a3a56e63463e3e88ebbc6e046fe53381&t=1650978148'} alt="true" />
          </span>
        </li>
        <li>
          <h4>3、匹配专家</h4>
          <p>根据不同行业，匹配最佳HR专家与您联系。</p>
          <span>
            <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/qianjin.png?sign=a3a56e63463e3e88ebbc6e046fe53381&t=1650978148'} alt="true" />
          </span>
        </li>
        <li>
          <h4>4、导师服务</h4>
          <p>3个工作日内完成服务（可申请加急）。</p>
          <span>
            <img src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/qianjin.png?sign=a3a56e63463e3e88ebbc6e046fe53381&t=1650978148'} alt="true" />
          </span>
        </li>
        <li>
          <h4>5、验收确认</h4>
          <p>完成服务。</p>
        </li>
      </ul>
      <div className="btn_get">申请服务</div>
    </div>
  )
}

export default Process;


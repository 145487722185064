/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2022-09-05 20:20:11
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-01 16:17:51
 * @FilePath: /greenet-resume-project/src/reduxStroe/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { configureStore } from '@reduxjs/toolkit';
import global from './global';
import { IGlobalState } from './interface';

export default configureStore({
  reducer: {
    global,
  },
});
declare module 'react-redux' {
  interface DefaultRootState {
    global: IGlobalState;
  }
}

import React from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QRCode from 'qrcode.react';
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import intershipInfo from './intershipInfo.png';
import onLiving from './onLiving.png';
import './index.less';

export const ProductData = [
  {
    title: '实习信息',
    url: 'https://render.alipay.com/p/f/fd-jauizbz9/index.html',
    image: intershipInfo,
  },
  {
    title: '优加视频号',
    url: 'https://render.alipay.com/p/f/fd-jauj8apg/index.html',
    image: onLiving,
  },
  {
    title: '优加小程序',
    url: 'http://www.anijue.com/p/q/j5rxse2a/pages/home/index.html',
    image: 'https://gw.alipayobjects.com/zos/rmsportal/yKHLCeGLgdFoxFXhzFtu.jpg',
  },
];
export default function Product() {
  const children = ProductData.map((d, i) => {
    if (i > 2) {
      return null;
    }
    return (
      <Col key={i} className="col" span={8}>
        <div className="device device-iphone-x">
          <div className="device-frame">
            <div className="device-content">
              <div className="content-wrapper home-hover">
                <div className="image" style={{ backgroundImage: `url(${d.image})` }} />
                <div className="code-wrapper">
                  <h4>扫码了解详情</h4>
                  <QRCode value={d.url} size={160} />
                </div>
              </div>
            </div>
          </div>
          <div className="device-stripe"></div>
          <div className="device-header"></div>
          <div className="device-sensors"></div>
          <div className="device-btns"></div>
          <div className="device-power"></div>
          <div className="device-home"></div>
        </div>
      </Col>
    );
  });
  return (
    <div className="home-layout-wrapper home-case-wrapper">
      <OverPack className="home-layout" playScale={0.4}>
        <QueueAnim
          className="home-case"
          type="bottom"
          key="home-case"
          ease="easeOutQuart"
          leaveReverse
        >
          <h2 key="h2">优加实习交流群</h2>
          <i key="i" className="line" />
          <QueueAnim
            key="content"
            component={Row}
            type="bottom"
            className="ss"
            style={{ display: 'flex', flexDirection: Row, marginLeft: 0 }}
            componentProps={{ gutter: 171 }}
          >
            {children}
          </QueueAnim>
        </QueueAnim>
      </OverPack>
    </div>
  );
}

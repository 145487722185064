import React, { useState } from 'react';
import './index.less';

const Product = () => {
  const [headerId, setheaderId] = useState(1);

  const headers = [
    {
      id: 1,
      title: '实习版',
      describe: '适用于求职实习机会的在校生',
      color: '#f8f8f8',
      icon: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/work1.png?sign=106f2d5772a0252e415f49102d1b26af&t=1648877249',
      children: [
        {
          content: '显著提升互联网及商科求职成功率',
        },
        {
          content: '零经验和实习大牛，都可以帮到你',
        },
        {
          content: '往期用户平均2-3天收到面试邀请',
        },
      ],
      contentActive: [
        {
          text: '无论你是否零经验在找第一份实习无从下笔，还是不知道怎样面对明天的面试，在这里你都可以得到最契合自身的帮助'
        },
        {
          text: '站在用人方的角度为你撰写简历、辅导面试，往期用户平均投递后 9.4 天收到 Offer'
        },
      ]
    },
    {
      id: 2,
      title: '校招版',
      describe: '适用于投递校招的应届生',
      color: '#fcfcfc',
      icon: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/work2.png?sign=6fc8f1eefe4d1ad96cd4ed5a8f42f6ac&t=1648877264',
      children: [
        {
          content: '导师全部具备校招全流程经验',
        },
        {
          content: '国内外各校招岗位全覆盖',
        },
        {
          content: '平均提升4倍以上的投递通过率',
        }
      ],
      contentActive: [
        {
          text: '校招是每个职场人的第一步，从简历到面试再到职业规划，每个人的职场之路都值得慎重对待'
        },
        {
          text: '优加实习已累计为上万名校招求职者量身提供了简历、面试、职业规划等服务，往期用户入职率高于应届生入职率 27.2 个百分点'
        },
      ]
    },
    {
      id: 3,
      title: '在职版',
      describe: '适用于已有工作经验的职场人士',
      color: '#f8f8f8',
      icon: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/modify/modify_icon/work3.png?sign=dbc301205c984a3c5a2b69fa7b475a13&t=1648877276',
      children: [
        {
          content: '从HR角度，挖掘个人经历，展现亮点',
        },
        {
          content: '符合人岗匹配模型，用人方一秒Pick',
        },
        {
          content: '平均提升2倍以上的面试邀约率',
        }
      ],
      contentActive: [
        {
          text: '就业大环境困难的当下，专业的求职服务是你在求职大军中脱颖而出的关键'
        },
        {
          text: '各行业资深HR任你挑选，一对一沟通百分百定制，为你量身打造一份专属于你的简历、面试、规划方案。已服务上千名用户，至今零差评'
        },
      ]
    },
  ];

  return (
    <div className="caseIndex">
      <div className="casetit">产品简介</div>
      <div className="clearfix">
        {headers.map((header, index) => (
          <div
            className="clearfix-item"
            key={index}
            style={{
              backgroundColor: `${headerId === header.id ? '#fff' : header.color}`,
              width: `${headerId === header.id ? '420px' : '306px'}`,
              height: `${headerId === header.id ? '468px' : '408px'}`,
              boxShadow: `${headerId === header.id ? '0 0 30px 0 rgb(0 9 85 / 8%)' : 'none'}`,
              transition: '0.4s'
            }}
            onMouseEnter={() => {
              setheaderId(header.id)
            }}
          >
            <div className="item-header">
              <div className="item-header-title"
                style={{
                  transform: `${headerId === header.id ? 'translate(55px,0px)' : 'none'}`,
                  transition: '0.4s'
                }}
              >{header.title}</div>
              <div className="item-header-describe"
                style={{
                  transform: `${headerId === header.id ? 'translate(50px,0px)' : 'none'}`,
                  transition: '0.4s'
                }}
              >{header.describe}</div>
            </div>
            <div className="item-icon">
              <img src={header.icon} className="icon" alt="true"
                style={{
                  transform: `${headerId === header.id ? 'translate(-120px,-120px)' : 'none'}`,
                  transition: '0.4s'
                }}
              />
            </div>
            <div className="itemList" >
              {headerId !== header.id ?
                header.children.map((children, index) => (
                  <p key={index} className="list">
                    {children.content}
                  </p>
                )) :
                header.contentActive.map((content, index) => (
                  <p key={index} className="contentActive">
                    {content.text}
                  </p>
                ))
              }
            </div>
            {headerId === header.id &&
              <div className="mid-indexButtons">立即优化</div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;

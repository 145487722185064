import { ACTION_TYPE } from './action/actionType';

export const defaultBaseInfoValue = {
  avatar: '',
  username: '',
  uid: '',
  email: '',
  mobile: '',
  country: '',
  province: '',
  city: '',
  sex: '',
  birth: 0,
};
const reducer = (state = defaultBaseInfoValue, action = {}) => {
  switch (action.type) {
    case ACTION_TYPE.LOGO_INFO:
      return action.values;
    default:
      return state;
  }
};
export default reducer;

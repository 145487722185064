import './index.less';
import React from 'react';
import ResumeHeader from './components/resumeHeader';
import ResumeTab from './components/resumeTab';
import AnalyseCard from './components/analyseCard';
import Recommend from './components/recommend';
import { useMount } from 'ahooks';

const Resume = () => {
  useMount(()=>{
    window.scrollTo(0, 0);
  })
  const analyseCard = [
    {
      id:1,
      icon:'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/analy-icon.png?sign=24f25b25966233f07c5cb647f8bee590&t=1652172805',
      title:'简历分析报告',
      image:'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/analy-img.png?sign=e89615263d0833e9200430b86e5cd456&t=1652169859',
      state:'暂无报告',
      content:'AI 智能优化简历，精准匹配热门职位'
    },
    {
      id:2,
      icon:'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/plan-icon.png?sign=7336a096b6334b01eebb8edf35163cf9&t=1652169869',
      title:'专属求职规划',
      image:'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/plan-img.png?sign=83451f23bb5166d7e95a5d0a971bc286&t=1652169877',
      state:'暂未体验',
      content:'大数据帮你规划最适合的职业发展路径'
    }
  ]
  return (
    <div className="resume">
      <div className="resume-content">
        <div className="resume-content-left">
          <ResumeHeader />
          <ResumeTab />
        </div>
        <div className="resume-content-right">
          <div className="resume-content-right-analyseCard">
           {analyseCard.map( item =>{
            return <AnalyseCard analyseCard={item} key={item.id}/>
           })}
          </div>
          <div className="resume-content-right-recommend">
            <Recommend />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Resume;

import React from 'react';
import { Card, Avatar, Typography } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import './index.less';

const { Paragraph } = Typography;
const RecruitInfoItem = (props) => {
  const { recruitInfo } = props;
  const getState = (state) => {
    switch (state) {
      case 0:
        return (
          <div className="state" style={{ color: 'gray' }}>
            <span className="state-icon" style={{ background: 'gray' }} />
            未开始
          </div>
        );
      case 1:
        return (
          <div className="state" style={{ color: 'rgb(39, 173, 96)' }}>
            {' '}
            <span className="state-icon" style={{ background: 'rgb(39, 173, 96)' }} />
            进行中
          </div>
        );
      case 2:
        return (
          <div className="state" style={{ color: 'red' }}>
            {' '}
            <span className="state-icon" style={{ background: 'red' }} />
            已结束
          </div>
        );
      default:
        return (
          <div className="state">
            {' '}
            <span className="state-icon" style={{ background: 'gray' }} />
            未开始
          </div>
        );
    }
  };
  return (
    <div className="recruit-info-item">
      <div className="recruit-title">招聘动态</div>
      <div className="recruit-item">
        {recruitInfo.recruitinfo.map((item) => {
          return (
            <Card style={{ width: 330, margin: 16 }} hoverable>
              <Card.Meta
                avatar={<Avatar src={item.logo} />}
                title={item.name}
                description={
                  <div
                    // className="recruit-item-code"
                    style={{ display: 'inline-block', fontSize: 12 }}
                  >
                    投递状态：{getState(item.state)}
                  </div>
                }
              />
              <div className="recruit-item-code" style={{ marginTop: 7 }}>
                内推码：
                <Paragraph
                  copyable={{ tooltips: false }}
                  style={{ display: 'inline-block', marginBottom: 0 }}
                >
                  <span style={{ fontSize: 20, color: '#ff4906', letterSpacing: -1 }}>
                    {item.pushCode}
                  </span>
                </Paragraph>
              </div>
              <div className="recruit-item-btn">
                <div className="recruit-item-date" style={{ fontSize: 12 }}>
                  更新时间：{item.updateDate}
                </div>
                <div className="recruit-item-website">
                  <a href={item.webSite} style={{ color: '#ff4906' }}>
                    去投递简历
                    <SwapRightOutlined />
                  </a>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};
export default RecruitInfoItem;

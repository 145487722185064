/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2023-02-27 11:11:24
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-06 19:59:58
 * @FilePath: /greenet-resume-project/src/pages/home/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Footer from '../components/footer';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { personalData } from '../../common/contant';
import Header, { HeaderItems } from '../components/header';
import Interviews from './pages/interviews';
import Modify from './pages/modify';
import Resume from './pages/resume';
import Personal from './pages/personal';
import RecruitInfo from './pages/RecruitInfo';
import ServiceSelection from './pages/ServiceSelection';
import Supervip from './pages/superVip';
import ChoseType from './pages/choseType';
import Index from './pages';
import './index.less';
import Order from './pages/personal/components/order';
import VipSuccessCard from './pages/VipSuccess/index';
const Home = () => {
  useEffect(() => {
    // document.querySelector('.good-cases')?.scrollIntoView();
  }, []);
  return (
    <div className="home">
      <Header />
      <Switch>
        <Route exact path="/modify" component={Modify} />
        <Route exact path="/resume" component={Resume} />
        <Route exact path="/interviews" component={Interviews} />
        <Route exact path="/personalCenter" component={Personal} />
        <Route exact path="/superVip" component={Supervip} />
        <Route exact path="/serviceSelection" component={ServiceSelection} />
        <Route exact path="/order" component={Order} />
        <Route exact path="/recruitInfo" component={RecruitInfo} />
        <Route exact path="/choseType" component={ChoseType} />
        <Route exact path="/" component={Index} />
        <Route exact path="/vipSuccess" component={VipSuccessCard} />
      </Switch>
      <Footer />
    </div>
  );
};
export default Home;
export const HomeRoutes = [
  ...HeaderItems.map((el) => el.link),
  ...personalData.map((el) => el.link),
  '/serviceSelection',
  '/choseType',
  '/recruitInfo',
  '/resume',
  '/vipSuccess',
];

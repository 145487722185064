import { useBoolean } from 'ahooks';
// import logoImg from 'assets/image/logo.png';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
// import useGetUserInfo from 'hooks/useGetUserInfo';
import {
  Avatar,
  Col,
  Layout,
  Menu,
  Row,
  Space,
  Badge,
  Button,
  Input,
  Modal,
  Radio,
  Typography,
} from 'antd';
import { pathToRegexp } from 'path-to-regexp';
import downloadPDF from '../../common/html2pdf.ts';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { allRouterPaths, menuOpenKeys, navs } from '../../router';
import message from '../../common/message';
import Guard from '../../router/Guard';
import { isAuth } from '../../router/renderRoutes';
import CVDrawer from '../Drawer';
import './index.less';

const { Header, Content, Sider, Footer } = Layout;

const AllianceLayout = () => {
  const [navSelectKeys, setNavSelectKeys] = useState([]);
  const [, { setTrue, setFalse }] = useBoolean(true);
  const [currentPath, setCurrentPath] = useState('');
  // const [collapsed, setCollapsed] = useState(false);
  const [CVDrawerVisible, setCVDrawerVisibile] = useState(false);
  const [CVDrawerData, setCVDrawerData] = useState({ title: '' });
  //   const { userInfo } = useGetUserInfo();
  const auth = useSelector((state) => state.global.auth);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [resumeName, setResumeName] = useState('我的简历');
  /**
   * Header共有三种样式:
   * 0-登录页login: 无header
   * 1-未登录首页PC home: 左边logo，右边登录注册按钮
   * 2-PC已登录除登录外所有页面: 左边logo，中间menu，右边用户名
   * 3-移动端首页: 左边logo，不区分登录态
   */
  const menuLeftList = [
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E9%AA%8C%E6%94%B6%E6%A8%A1%E7%89%88%E7%AE%A1%E7%90%86.png?sign=a45e74a83d5d4f3376fc93e8f48bd85a&t=1636286607',
      title: '模版管理',
      count: 1,
    },
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/ic_%E6%9B%B4%E6%8D%A2.png?sign=1ff7ff9a1533ab8ab357e6da434f1668&t=1636286682',
      title: '更换模版',
      count: 4,
    },
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E4%B8%BB%E9%A2%98.png?sign=83ec483965721b3fe32a75f30ecb4033&t=1636286772',
      title: '主题设置',
      count: 3,
    },
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E5%AF%BC%E5%85%A5.png?sign=2905040bea866eca857bf8977547cdf1&t=1636286659',
      title: '倒入简历',
      count: 0,
    },
  ];
  const menuRightList = [
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E5%B0%8F%E8%B4%B4%E5%A3%AB.png?sign=2845b5f2129347b3979672b8577aa7cf&t=1636386777',
      title: '贴士',
      count: 1,
    },
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E8%AF%8A%E6%96%AD.png?sign=e3ace02a90a5c83b1e605dcb2074f6b0&t=1636386747',
      title: '诊断',
      count: 4,
    },
    {
      icon: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E6%A1%88%E4%BE%8B%20(1).png?sign=4f7bb20b3f7e03675cf1d8d1e34748ef&t=1636386805',
      title: '案例',
      count: 3,
    },
  ];

  // const toggle = () => {
  //   setCollapsed(!collapsed);
  // };

  // const handleLogout = async () => {
  //   try {
  //     return;
  //   // eslint-disable-next-line no-unreachable
  //   } catch (e) {
  //     console.error('logout error', e);
  //   }
  // };
  // const userMenus = (
  //   <Menu
  //     onClick={(e) => {
  //       if (e.key === 'layout') {
  //         handleLogout();
  //       }
  //     }}
  //   >
  //     <Menu.Item key="layout">退出登录</Menu.Item>
  //   </Menu>
  // );
  useEffect(() => {
    const path = allRouterPaths.find(
      (routePath) => {
        return pathToRegexp(routePath).test(location.pathname);
      },

      //     pathToRegexp(routePath).test(location.pathname),
    );

    setCurrentPath(path ?? '');
  }, [currentPath, location.pathname]);
  useEffect(() => {
    const navKeys = navs
      .filter((nav) => location.pathname.startsWith(nav.path))
      .map((nav) => nav.path);
    if (navKeys && navKeys.length > 0) {
      setNavSelectKeys(navKeys);
    } else {
      setNavSelectKeys([]);
    }
  }, [location.pathname, setFalse, setTrue]);
  useEffect(() => {
    const a = allRouterPaths.some((p) => {
      return location.pathname !== p && location.pathname.includes(p);
    });

    a ? setTrue() : setFalse();
  }, [location.pathname, setFalse, setTrue]);
  const renderMenu = useCallback(
    (routes) => {
      return routes.map((route) => {
        if (!route.meta || !isAuth(auth, route.identify)) {
          return null;
        }
        if (route.routes) {
          return (
            <Menu.SubMenu icon={route.meta?.icon} title={route.meta?.title} key={route.path}>
              {renderMenu(route.routes)}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item icon={route.meta?.icon} key={route.path}>
            {route.meta?.title}
          </Menu.Item>
        );
      });
    },
    [auth],
  );
  // const renderSideMenu = useCallback(() => {
  //   const rootRoute = navs.find((route) => location.pathname.startsWith(route.path));
  //   if (!rootRoute) {
  //     return null;
  //   }
  //   if (!rootRoute.routes) {
  //     return null;
  //   }
  //   return renderMenu(rootRoute.routes);
  // }, [location.pathname, renderMenu]);
  const html2canvas = async () => {
    const HtmlValue = document.getElementById('resume$');

    setLoading(true);

    try {
      await downloadPDF(HtmlValue, resumeName, false, () => {});
      setTimeout(() => {
        setLoading(false);
        setConfirmVisible(false);
        message.success('导出简历成功');
      }, 2000);
    } catch (e) {
      message.error('导出简历出错');
      setLoading(false);
    }
  };
  return (
    <div className="layout-wrapper">
      <Layout style={{ height: 'auto' }}>
        <Header className="layout-header" style={{ lineHeight: '62px' }}>
          <Row>
            <Col flex="208px" className="logo-wrapper">
              <img
                onClick={() => {
                  history.push('/');
                }}
                src="https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/image-vc/7777-removebg-preview.png?sign=ab767de813437c4a597b1e08ff2c37a7&t=1648814629"
                width="114"
                alt=""
              />
            </Col>
            <Col flex="auto">
              <Menu
                mode="horizontal"
                selectedKeys={navSelectKeys}
                onClick={(e) => {
                  history.push(e.key);
                }}
              >
                {navs.map((route) => {
                  return (
                    <Menu.Item key={route.path} icon={route.meta?.icon}>
                      {route.meta?.title}
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Col>
            <Col flex="350px" style={{ textAlign: 'center' }}>
              <Space align="start" style={{ height: 64 }}>
                <Input
                  prefix={<EditOutlined />}
                  defaultValue={resumeName}
                  value={resumeName}
                  onChange={(e) => {
                    setResumeName(e.target.value);
                  }}
                />
              </Space>
            </Col>
            <Col flex="350px" style={{ textAlign: 'center' }}>
              <Space align="start" style={{ height: 64 }}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                  danger
                  onClick={() => {
                    setConfirmVisible(true);
                  }}
                >
                  下载
                </Button>
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout style={{ backgroundColor: '#39394d' }}>
          {false ? (
            <Sider
              className="layout-sider-left"
              trigger={null}
              collapsible
              // collapsed={collapsed}
              width={120}
              theme="light"
            >
              <Menu
                mode="inline"
                onClick={(e) => {
                  //    history.push(e.key);
                }}
                defaultOpenKeys={menuOpenKeys}
                selectedKeys={[currentPath]}
              >
                {menuLeftList.map((item) => {
                  return (
                    <Menu.Item className="layout-sider-left-menuItem" key={item.title}>
                      <div className="menuItem-box">
                        <div style={{ width: '100%', height: 35 }}>
                          <Badge count={item.count}>
                            <Avatar shape="square" src={item.icon} />
                          </Badge>
                        </div>
                        <div style={{ width: '100%', height: 35, fontSize: 13, fontWeight: 500 }}>
                          {item.title}
                        </div>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Sider>
          ) : null}
          <Content style={{ position: 'relative', padding: 24 }} className="layout-content">
            <Guard />
          </Content>
          {false && (
            <Sider theme="light" className="layout-sider-right" width={120} id="www">
              <Menu
                mode="inline"
                defaultOpenKeys={menuOpenKeys}
                selectedKeys={[currentPath]}
                //               style={{ position: 'relative' }}
              >
                <CVDrawer
                  visible={CVDrawerVisible}
                  data={CVDrawerData}
                  menuRightList={menuRightList}
                  onClose={() => {
                    setCVDrawerVisibile(false);
                  }}
                />
                {menuRightList.map((item) => {
                  return (
                    <Menu.Item
                      key={item.title}
                      className="layout-sider-right-menuItem"
                      onClick={(e) => {
                        setCVDrawerVisibile(true);
                        setCVDrawerData({ title: item.title });
                      }}
                    >
                      <div className="menuItem-box">
                        <div style={{ width: '100%', height: 35 }}>
                          <Badge count={item.count}>
                            <Avatar shape="square" src={item.icon} />
                          </Badge>
                        </div>
                        <div className="menuItem-text">{item.title}</div>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Sider>
          )}
        </Layout>
        <Footer style={{ textAlign: 'center', color: '#333' }}>
          www.youjia.sx.cn©2021.09 Created by Greenet{' '}
          <Typography.Link herf="https://beian.miit.gov.cn/">京ICP备2022007030号-4</Typography.Link>
        </Footer>
      </Layout>
      <Modal
        title="导出信息确认"
        open={confirmVisible}
        onOk={html2canvas}
        footer={null}
        onCancel={() => {
          setConfirmVisible(false);
        }}
        className="pdf-confirm"
        centered
        okText="导出"
        cancelText="取消"
      >
        <div className="pdf-confirm-box">
          <div className="pdf-confirm-box-name">
            <Row>
              <Col flex="100px">简历名称:</Col>
              <Col>
                <Input
                  prefix={<EditOutlined />}
                  defaultValue={resumeName}
                  value={resumeName}
                  onChange={(e) => {
                    setResumeName(e.target.value);
                  }}
                />
                <p className="pdf-confirm-box-name-tips">
                  推荐简历名称: 姓名-岗位-实习时长-学校(张三-直播运营-3个月以上-优加实习大学)
                </p>
              </Col>
            </Row>
          </div>{' '}
          <div className="pdf-confirm-box-name">
            <Row>
              <Col flex="100px">所选套餐:</Col>
              <Col>
                <Radio.Group value={1}>
                  <Radio value={1}>
                    <span>月季会员</span>
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </div>
          <div className="pdf-confirm-box-name">
            <Row>
              <Col flex="100px">
                <div></div>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  width="200px"
                  danger
                  onClick={html2canvas}
                  size={'large'}
                  loading={loading}
                >
                  <span style={{ width: 200 }}>导出</span>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AllianceLayout;

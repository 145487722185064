import { Modal, Spin, Image } from 'antd';
import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { asyncFetchBaseUserInfo, asyncFetchQueryAccount } from '../../reduxStroe/global';
import message from '../../common/message';
import { loginWechatUsingGET, checkLoginStatusUsingGET } from '../../api/web-controller';
import { useRequest, useSetState, useUpdateEffect } from 'ahooks';
import './index.less';

const logoUrl =
  'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/%E7%BD%91%E7%AB%99logo/logo.JPG?sign=bcb9d07d10304f151e62e7146bbd40b6&t=1648001975';
const fildUrl =
  'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/youjia/FILD.jpg?sign=3c054870e604147318be4f70054a44c9&t=1648469688';
const USER_STYLE = {
  HUNTER: 1,
  INDUCTOR: 2,
};
const QR_RESULT = {
  SUCCESS: 1,
  FAILD: 2,
  TIME_OVER: 3,
};
const Login = (props) => {
  const { isVisiable, setIsVisiable, setIsLogin } = props;
  const [QrResult, setQrResult] = useSetState({
    loginCodeUrl: fildUrl,
    randomId: '',
  });
  const [qrLoading, setQrLoading] = useState(false);
  const [qrStatus, setQrStatus] = useState(QR_RESULT.TIME_OVER);
  const [value, setValue] = useState(1);
  const dispatch = useDispatch();
  const getQrCodeUrl = async () => {
    try {
      sessionStorage.setItem('preLogin', 'true');
      setQrLoading(true);
      const { data, result } = await loginWechatUsingGET();
      if (result === 1) {
        setQrResult({
          loginCodeUrl: data.qrCodeUrl,
          randomId: data.randomId,
        });
        run({ randomId: data.randomId });
        setTime();
        setQrStatus(QR_RESULT.SUCCESS);
      }
    } catch (e) {
      message.error(e.error_msg, '获取二维码失败');
      setQrStatus(QR_RESULT.FAILD);
    } finally {
      setQrLoading(false);
    }
  };

  const { run, cancel } = useRequest(checkLoginStatusUsingGET, {
    manual: true,
    pollingInterval: 500,
    onSuccess: (data, param) => {
      if (data.data) {
        cancel();
        setIsVisiable(false);
        message.success('登陆成功');
        sessionStorage.removeItem('preLogin');
        setIsLogin(true);
        dispatch(asyncFetchBaseUserInfo({}));
        dispatch(asyncFetchQueryAccount({}));
      }
    },
    onError: (e) => {
      cancel();
      message.error(e.error_msg, '获取用户登陆状态失败');
      setQrStatus(QR_RESULT.TIME_OVER);
    },
  });
  const setTime = () => {
    setTimeout(() => {
      setQrStatus(QR_RESULT.TIME_OVER);
    }, 60000);
  };
  useUpdateEffect(() => {
    isVisiable && getQrCodeUrl();
  }, [props.isVisiable]);
  useUpdateEffect(() => {
    qrStatus !== QR_RESULT.SUCCESS && cancel();
  }, [qrStatus]);

  return (
    <Modal
      footer={null}
      open={isVisiable}
      onCancel={() => {
        setIsVisiable(false);
      }}
      className="login"
      width={420}
      centered
    >
      <div style={{ textAlign: 'center', height: 400 }}>
        <div className="login-title">
          <div
            className={classnames('login-title-left', {
              active: value === USER_STYLE.HUNTER,
            })}
            onClick={() => {
              setValue(USER_STYLE.HUNTER);
            }}
          >
            我是求职者
          </div>
          <div
            className={classnames('login-title-right', { active: value === USER_STYLE.INDUCTOR })}
            onClick={() => {
              setValue(USER_STYLE.INDUCTOR);
              // setLoading(true);
            }}
          >
            我是入驻者
          </div>
        </div>

        <div className="qr">
          <Spin spinning={qrLoading}>
            {qrStatus === QR_RESULT.SUCCESS && (
              <Fragment>
                <Image
                  className="qr-imageCode"
                  width={200}
                  src={QrResult.loginCodeUrl}
                  preview={false}
                />
                <div className="qr-logo">
                  <Image preview={false} width={33} src={logoUrl} />
                </div>
              </Fragment>
            )}
            {qrStatus === QR_RESULT.FAILD && (
              <Fragment>
                <Image
                  className="qr-imageCode"
                  width={200}
                  src={QrResult.loginCodeUrl}
                  preview={false}
                />
                <div className="qr-fild">
                  <div
                    className="button"
                    onClick={() => {
                      getQrCodeUrl();
                    }}
                  >
                    点击刷新
                  </div>
                  <p>二维码加载失败</p>
                </div>
              </Fragment>
            )}
            {qrStatus === QR_RESULT.TIME_OVER && (
              <Fragment>
                <Image
                  className="qr-imageCode"
                  width={200}
                  src={QrResult.loginCodeUrl}
                  preview={false}
                />
                <div className="qr-fild">
                  <div
                    className="button"
                    onClick={() => {
                      getQrCodeUrl();
                    }}
                  >
                    点击刷新
                  </div>
                  <p>二维码已失效</p>
                </div>
              </Fragment>
            )}
          </Spin>
        </div>
        <div className="login-text">
          <span className="iconfont icon-weixinmendian"></span>
          <span className="text">使用微信扫码登录</span>
        </div>
        <p className="privacy">登陆即代表同意《服务协议》《用户隐私条款》</p>
      </div>
    </Modal>
  );
};
export default Login;

import 'rc-banner-anim/assets/index.css';
import './index.less';
import { Carousel } from 'antd';

const image = [
  {
    url: 'https://uploadfiles.nowcoder.com/images/20220920/707480515_1663645929376/36C5D00B2A74A8BD829DA385321B369F',
  },
  {
    url: 'https://uploadfiles.nowcoder.com/images/20220920/707480515_1663658542635/8A3D09CB0017381D08A24C727570DE0F',
  },
  {
    url: 'https://uploadfiles.nowcoder.com/images/20220829/707480515_1661771274273/1ABAE17862701F32776A2C97B8C81FF7',
  },
];
const RecruitInfoBanner = () => {
  return (
    <div className="recruit-info-banner">
      <Carousel autoplay>
        {image.map((url) => {
          return (
            <div className='recruit-info-banner-item'>
              <img src={url.url} alt=""/>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default RecruitInfoBanner;

/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2023-07-26 14:01:58
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-06 18:39:05
 * @FilePath: /greenet-resume-project/src/api/wei-xin-pay-controller.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import {
  PayStatusType,
  PayloadProps,
  WechatPayControllerCreateNativeTypeResponseType,
  WechatPayControllerQueryByOutTradeNoResponesType,
  PayVipProps,
  WechatPayControllerResponseType,
  WechatPayControllerCreateNativeType,
  WechatPayControllerQueryAccountGETResponse,
} from './model';

export async function WechatPayControllerCreateNativePOST(
  payload: PayloadProps,
): Promise<WechatPayControllerCreateNativeTypeResponseType> {
  const data = {
    ...payload,
  };
  const headers = Object.create(null);
  headers['Content-Type'] = 'application/json';
  const result = await axios.request({
    url: `/api/WechatPayController/createNative`,
    method: 'post',
    data,
    headers,
  });
  return result.data;
}
export async function WechatPayControllerQueryByOutTradeNoGet(
  out_trade_no: string,
): Promise<WechatPayControllerQueryByOutTradeNoResponesType> {
  const result = await axios.request({
    url: `/api/WechatPayController/QueryByOutTradeNo?out_trade_no=${out_trade_no}`,
    method: 'get',
  });
  return result.data;
}
export async function WechatPayControllerQueryPayStatusPOST(params: PayStatusType) {
  const data = {
    ...params,
  };
  const headers = Object.create(null);
  headers['Content-Type'] = 'application/json';
  const result = await axios.request({
    url: `/api/WechatPayController/QueryPayStatus`,
    method: 'post',
    data,
    headers,
  });
  return result.data;
}

export async function WechatPayControllerCreateVIPNativePOST(
  payload: PayVipProps,
): Promise<WechatPayControllerResponseType<WechatPayControllerCreateNativeType>> {
  const data = {
    ...payload,
  };
  const headers = Object.create(null);
  headers['Content-Type'] = 'application/json';
  const result = await axios.request({
    url: `/api/WechatPayController/createVIPNative`,
    method: 'post',
    data,
    headers,
  });
  return result.data;
}
export async function WechatPayControllerQueryAccountGET(): Promise<
  WechatPayControllerResponseType<WechatPayControllerQueryAccountGETResponse>
> {
  const result = await axios.request({
    url: `/api/WechatPayController/queryAccount`,
    method: 'get',
  });
  return result.data;
}

import React, { Fragment, useState, useRef } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip, Avatar, Form, Modal, Steps, Button } from 'antd';
import YInput from 'pages/make/common/YInput';
import YSelect from 'pages/make/common/YSelect';

import Cookies from 'js-cookie';
import {
  EducationOptions,
  industryOptions,
  isStudyAbroadOptions,
  isExpeditedOptions,
  luanguageOptions,
  experienceOptions,
} from 'pages/make/common/contants';
import Draggable from 'react-draggable';
import classnames from 'classnames';
import { getSearchParam } from 'common/ultils';
import { serviceType } from './contant';
import './index.less';
import { useMount, useSetState } from 'ahooks';
import Question from './components/Question';
import PayConfirm from './components/payConfirm';
import message from 'common/message';
import hot from 'common/hot.png';
const formStyle = {
  maxWidth: 'none',
  padding: '24px 0',
  margin: '0 auto',
  alignItems: 'center',
};

const ServiceSelection = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [couponsForm] = Form.useForm();
  const [formCondition] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const { type, order } = getSearchParam(location.search);
  const [count, setCount] = useState(0);
  const [coupons, setCoupons] = useState(false);
  const [open, setOpen] = useState(false);
  const [preNum, setPreNum] = useState(0);
  const [isVisiable, setIsVisiable] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef(null);
  const [conditionValue, setConditionValue] = useSetState({
    education: '【大学本科】',
    experience: '【无求职经验】',
    luanguage: '【简体中文】',
    isStudyAbroad: '【无海外留学经验】',
    isExpedited: '【正常(3个工作日内)】',
    industry: '【互联网/IT】',
  });
  const [orderTyppe, setOrderTyppe] = useState([order]);
  const serviceItem = serviceType.filter((i) => {
    return i.type === type;
  })?.[0];
  const [servicePro, setServicePro] = useState([serviceItem]);
  const onChange = (value) => {
    setCurrent(value);
  };
  const nextStep = () => {
    setCurrent(() => current + 1);
  };
  const preStep = () => {
    setCurrent(() => current - 1);
  };
  const submitHandel = () => {
    try {
      setCurrent(() => current + 1);
    } catch (e) {
      console.log(e);
    }
  };
  const choseService = (orderType) => {
    setCoupons(false);
    const arr = orderTyppe.includes(orderType)
      ? orderTyppe.filter((item) => {
          return item !== orderType;
        })
      : [...orderTyppe, orderType];

    if (!orderTyppe.includes(orderType)) {
      setCount(() => {
        return count + serviceItem?.serviceContent.filter((i) => i.orderType === orderType)[0].num;
      });
      setPreNum(() => {
        return (
          preNum + serviceItem?.serviceContent.filter((i) => i.orderType === orderType)[0].preNum
        );
      });
    } else {
      setCount(() => {
        return count - serviceItem?.serviceContent.filter((i) => i.orderType === orderType)[0].num;
      });
      setPreNum(() => {
        return (
          preNum - serviceItem?.serviceContent.filter((i) => i.orderType === orderType)[0].preNum
        );
      });
    }
    setServicePro(() => {
      return serviceItem?.serviceContent.filter((i) => arr.includes(i.orderType));
    });
    setOrderTyppe(arr);
  };
  const showModal = () => {
    if (!orderTyppe.length) {
      message.error('', '至少选择一款产品');
      return;
    }
    setOpen(true);
  };
  const handleOk = async (e) => {
    e.preventDefault();
    try {
      const localeValues = await couponsForm.validateFields();
      if (localeValues.couponsValue === '马学明') {
        setOpen(false);
        setCoupons(true);
        message.success('兑换成功');
      } else {
        message.error('兑换码错误', '兑换失败');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = (e) => {
    console.log(e);
    setOpen(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const getArrAndObjValue = (arrOptions, value) => {
    const res = arrOptions.filter((i) => {
      return i.value === value;
    })[0];
    return `【${res.label}】`;
  };
  const toPayFor = () => {
    if (Cookies.get('xxl_sso_sessionid')) {
      setIsVisiable(true);
      return;
    } else {
      history.push('/');
      message.error('您还未完成登陆，请先登陆');
    }
  };
  const educationChange = (e) => {
    setConditionValue({ education: `${getArrAndObjValue(EducationOptions, e)}` });
  };
  const industryChange = (e) => {
    setConditionValue({ industry: getArrAndObjValue(industryOptions, e) });
  };
  const isExpeditedChange = (e) => {
    setConditionValue({ isExpedited: getArrAndObjValue(isExpeditedOptions, e) });
  };
  const luanguageChange = (e) => {
    setConditionValue({ luanguage: getArrAndObjValue(luanguageOptions, e) });
  };
  const experienceChange = (e) => {
    setConditionValue({ experience: getArrAndObjValue(experienceOptions, e) });
  };
  const isStudyAbroadChange = (e) => {
    setConditionValue({ isStudyAbroad: getArrAndObjValue(isStudyAbroadOptions, e) });
  };

  useMount(() => {
    setOrderTyppe([order]);
    setCount(serviceItem?.serviceContent.filter((i) => i.orderType === order)[0].num);
    setPreNum(serviceItem?.serviceContent.filter((i) => i.orderType === order)[0].preNum);
    setServicePro(() => {
      return serviceItem?.serviceContent.filter((i) => [order].includes(i.orderType));
    });
  });

  return (
    <div className="service-page">
      <div className="service-content">
        <div className="service-info">
          <div className="service-info-title">
            <div className="num">1</div>
            <div>选择服务内容</div>
          </div>
          <div className="service-info-contant">
            {serviceItem?.serviceContent?.map((item, index) => {
              return (
                <Fragment key={item.orderType}>
                  <div className="service-info-contant-item">
                    <div className="service-item-header">
                      <h6>
                        {item.h6}
                        {(index === 0 || index === 1) && <img src={hot} alt="1" />}
                      </h6>
                      <Tooltip title={item.detail.tooltip} color="#505667">
                        <div className="service-item-header-detail">{item.detail.btn}</div>
                      </Tooltip>
                    </div>
                    <p className="service-item-desc">{item.desc}</p>
                    <div className="service-item-flooter">
                      <div className="service-item-flooter-left">
                        <Avatar.Group className="img">
                          {item.avatar.map((i) => {
                            return <Avatar size={20} src={i} key={i} />;
                          })}
                        </Avatar.Group>
                        <span>{item.userNum}</span>
                      </div>
                      <div
                        className={classnames('service-item-flooter-btn', {
                          active: orderTyppe.includes(item.orderType),
                        })}
                        onClick={() => {
                          choseService(item.orderType);
                        }}
                      >
                        {orderTyppe.includes(item.orderType) ? '已' : <i></i>}
                        选择
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className="service-info-title">
            <div className="num">2</div>
            <div>填写服务信息</div>
          </div>

          <div className="service-info-step">
            <div className="step-box">
              <Steps labelPlacement="vertical" size="small" onChange={onChange} current={current}>
                <Steps.Step title="服务条件" />
                <Steps.Step title="基础信息" />
              </Steps>
            </div>

            {current ? (
              <Form form={form} name="advanced_search" style={formStyle}>
                <Form.Item name="22" label="姓名" required>
                  <YInput placeholder="请输入姓名" />
                </Form.Item>{' '}
                <Form.Item name="电话" label="电话" required>
                  <YInput placeholder="请输入电话" />
                </Form.Item>
                <Form.Item name="11" label="性别" required>
                  <YInput placeholder="请输入姓名" />
                </Form.Item>{' '}
                <Form.Item name="11" label="学校" required>
                  <YInput placeholder="请输入学校" />
                </Form.Item>
                <Form.Item name="11" label="年级" required>
                  <YInput placeholder="请输入学校" />
                </Form.Item>
                <Form.Item name="11" label="专业" required>
                  <YInput placeholder="请输入学校" />
                </Form.Item>
              </Form>
            ) : (
              <Form
                form={formCondition}
                name="advanced_search"
                style={formStyle}
                initialValues={{
                  education: 2,
                  industry: 1,
                  isStudyAbroad: 1,
                  isExpedited: 1,
                  luanguage: 1,
                  experience: 1,
                }}
              >
                <Form.Item name="education" label="最高学历" required>
                  <YSelect
                    value={3}
                    placeholder="请选择学历"
                    options={EducationOptions}
                    onChange={educationChange}
                  />
                </Form.Item>{' '}
                <Form.Item name="industry" label="目标行业" required>
                  <YSelect
                    placeholder="请选择行业"
                    onChange={industryChange}
                    options={industryOptions}
                  />
                </Form.Item>
                <Form.Item name="isStudyAbroad" label="是否留学" required>
                  <YSelect
                    placeholder="是否留学"
                    options={isStudyAbroadOptions}
                    onChange={isStudyAbroadChange}
                  />
                </Form.Item>{' '}
                <Form.Item name="isExpedited" label="是否加急" required>
                  <YSelect
                    placeholder="是否加急"
                    options={isExpeditedOptions}
                    onChange={isExpeditedChange}
                  />
                </Form.Item>
                <Form.Item name="luanguage" label="简历语言" required>
                  <YSelect
                    placeholder="请选择语言"
                    onChange={luanguageChange}
                    options={luanguageOptions}
                  />
                </Form.Item>
                <Form.Item name="experience" label="求职经验" required>
                  <YSelect
                    placeholder="请选择经验"
                    onChange={experienceChange}
                    options={experienceOptions}
                  />
                </Form.Item>
              </Form>
            )}
            <div className="btn">
              {!current && (
                <Button type="primary" danger onClick={nextStep}>
                  下一步
                </Button>
              )}
              {!!current && (
                <>
                  <Button type="dashed" danger onClick={preStep}>
                    上一步
                  </Button>
                  <Button type="primary" danger onClick={submitHandel}>
                    提交
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <Question />
      </div>
      <div className="service-floot">
        <div className="service-floot-cont">
          <div className="service-floot-cont-left">
            <div className="left-title">
              产品名称：
              <span>{`${serviceItem.title} ${fromateArray(servicePro).join(' + ')}`}</span>
            </div>
            <div className="left-title">
              服务信息：
              <span>{`${conditionValue.education}+ ${conditionValue.experience} + ${conditionValue.luanguage} + ${conditionValue.isStudyAbroad} + ${conditionValue.isExpedited} + ${conditionValue.industry}`}</span>
            </div>
          </div>
          <div className="service-floot-cont-right">
            <div className="oderCont">
              <div className="oder-cont-money">
                总计价格：
                <span>
                  ¥<span>{coupons ? 1 : count}</span>
                  <del className="pre-num">{preNum}</del>
                </span>
              </div>
              <div className="use-coupons" onClick={showModal}>
                优惠券使用 <RightOutlined />{' '}
              </div>
              <div className="service-agreement">
                <span>下单即默认同意《服务协议》</span>
              </div>
            </div>
            <div
              className="btnCont"
              onClick={() => {
                toPayFor();
              }}
            >
              立即购买
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="coupons-modal"
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
              textAlign: 'center',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onBlur={() => {}}
            // end
          >
            优惠券使用
          </div>
        }
        centered
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <Form form={couponsForm} name="advanced_search" style={formStyle}>
            <Form.Item
              name="couponsValue"
              label="兑换码"
              rules={[
                {
                  required: true,
                  message: '请输入兑换码',
                },
              ]}
            >
              <YInput placeholder="请输入兑换码" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <PayConfirm
        isVisiable={isVisiable}
        serviceItem={serviceItem}
        setIsVisiable={setIsVisiable}
        conditionValue={conditionValue}
        servicePro={servicePro}
        coupons={coupons}
        count={count}
      />
    </div>
  );
};
export default ServiceSelection;
export const fromateArray = (arr) => {
  return arr.map((i) => {
    return i.h6;
  });
};

import React from 'react';
import { Input } from 'antd';
import './index.less';

const YInput = (props) => {
  const { showCount = true, placeholder = '请输入', onChange, value } = props;

  return (
    <div className="y-input">
      <Input
        placeholder={placeholder}
        showCount={showCount}
        className={'Yinput'}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
export default YInput;

export const TYPE_FORM = {
  /**
   * input 输入
   */
  INPUT: 1,
  /**
   * select
   */
  SELECT: 2,
  /**
   *   日期
   */
  DATAPICKER: 3,
  /**
   *   范围日期
   */
  RANGEPICKER: 4,
};
export const politicsStatusoptions = [
  { label: '不填', value: 0 },
  {
    label: '中共党员',
    value: 1,
  },
  {
    label: '中共预备党员',
    value: 2,
  },
  {
    label: ' 共青团员',
    value: 3,
  },
  {
    label: ' 普通公民',
    value: 4,
  },
  {
    label: '群众',
    value: 5,
  },
];
export const maritalStatusOptions = [
  { label: '不填', value: 0 },
  {
    label: ' 未婚',
    value: 1,
  },
  {
    label: ' 已婚',
    value: 2,
  },
  {
    label: ' 离异',
    value: 3,
  },
  {
    label: ' 已婚已育',
    value: 4,
  },
];
export const sexOptions = [
  {
    label: '不填',
    value: 0,
  },
  {
    label: '男',
    value: 1,
  },
  {
    label: '女',
    value: 2,
  },
];
export const EducationOptions = [
  { label: '不填', value: 0 },
  {
    label: ' 大专',
    value: 1,
  },
  {
    label: ' 本科',
    value: 2,
  },
  {
    label: ' 硕士',
    value: 3,
  },
  {
    label: ' 博士',
    value: 4,
  },
  {
    label: ' MBA',
    value: 5,
  },
];
export const arrivalTimeOptions = [
  { label: '随时', value: 0 },
  {
    label: ' 一周内',
    value: 1,
  },
  {
    label: ' 两周内',
    value: 2,
  },
  {
    label: ' 一个月内',
    value: 3,
  },
  {
    label: ' 二个月内',
    value: 4,
  },
  {
    label: ' 三个月以上',
    value: 5,
  },
];
export const REFORM_STEP = {
  /**
   * input 输入
   */
  BASE: 1,
  /**
   * select
   */
  EDUCATE: 2,
  /**
   *   日期
   */
  JOBPOST: 3,
  /**
   *   实习
   */
  INTERSHIP: 4,
  PROJECT: 5,
  SCHOOL: 6,
  HONOR: 7,
  SELFSKILL: 10,
  HOBBY: 8,
  EVALUATE: 9,
  JONINTENTION: 11,
  /**
   *
   */
};
export const initialEducateValues = [
  {
    education: {
      value: 0,
      isShow: true,
      label: '',
    },
    endTime: {
      value: 1590940800000,
      isShow: true,
      label: '',
    },
    introductions: {
      value:
        '<p><span style="font-size:12px">💯<strong>专业成绩：<span style="color:#2c6fba">GPA 3.9 (专业5%)</span></strong></span></p><p class="p1"><span style="font-size:12px"><strong>主要课程:</strong>运筹学 (90)、运作管理、项目管理(91)、Python、决策分析(96)、Matlab与物流管理实验 </span></p><p><span style="font-size:12px"><a href="https://baidu.com" target="_blank">我的作品</a></span></p><p></p>',
      isShow: true,
      label: '',
    },
    major: {
      value: '计算机技术与科学',
      isShow: true,
      label: '',
    },
    schoolName: {
      value: '优加实习大学',
      isShow: true,
      label: '',
    },
    startTime: {
      value: 1441036800000,
      isShow: true,
      label: '',
    },
    upToNow: {
      value: false,
      isShow: true,
      label: '',
    },
  },
];
export const initialWorkExpirenceValues = [
  {
    endTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    introductions: {
      value:
        '<p><span style="font-size:14px"><strong>工作内容:</strong></span></p><p>① <span style="color:#333333"><span style="font-size:12px">第一阶段查询统计分析功能:基于 xampp (环境）+HTML+mysql+PHP+JS+百度地图 API 实现武汉公交客流站点、线路、区域的查询显示和时空统计分析同时将结果可视化；</span></span></p><p>② <span style="color:#333333"><span style="font-size:12px">第二阶段仿真功能:基于考虑乘客行为的 JS 仿真算法，实现工作日、节假日、以及不同天气对公交线路、站点的候车客流、断面客流进行仿真并且实现结果二维可视化;</span></span></p><p>③ <span style="color:#333333"><span style="font-size:12px">第三阶段预测功能:基于历史客流数据统计，使用三次指数平滑预测算法，实现未来某一天指定线路、站点上下车客流的预测。</span></span></p><p></p><p></p>',
      isShow: true,
      label: '',
    },
    personPosition: {
      value: '产品经理',
      isShow: true,
      label: '',
    },
    companyName: {
      value: '优加实习科技有限公司',
      isShow: true,
      label: '',
    },
    startTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    upToNow: {
      value: false,
      isShow: true,
      label: '',
    },
  },
];
export const initialInterShipValues = [
  {
    endTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    projectIntroduction: {
      value:
        '<p><span style="font-size:14px"><strong>工作内容:</strong></span></p><p>① <span style="color:#333333"><span style="font-size:12px">第一阶段查询统计分析功能:基于 xampp (环境）+HTML+mysql+PHP+JS+百度地图 API 实现武汉公交客流站点、线路、区域的查询显示和时空统计分析同时将结果可视化；</span></span></p><p>② <span style="color:#333333"><span style="font-size:12px">第二阶段仿真功能:基于考虑乘客行为的 JS 仿真算法，实现工作日、节假日、以及不同天气对公交线路、站点的候车客流、断面客流进行仿真并且实现结果二维可视化;</span></span></p><p>③ <span style="color:#333333"><span style="font-size:12px">第三阶段预测功能:基于历史客流数据统计，使用三次指数平滑预测算法，实现未来某一天指定线路、站点上下车客流的预测。</span></span></p><p></p><p></p>',
      isShow: true,
      label: '',
    },
    roleOfProject: {
      value: '产品经理',
      isShow: true,
      label: '',
    },
    experienceName: {
      value: '优加实习科技有限公司',
      isShow: true,
      label: '',
    },
    startTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    upToNow: {
      value: false,
      isShow: true,
      label: '',
    },
  },
];
export const initialProjectExpirenceValues = [
  {
    endTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    projectIntroduction: {
      value:
        '<p><span style="font-size:14px"><strong>工作内容:</strong></span></p><p>① <span style="color:#333333"><span style="font-size:12px">第一阶段查询统计分析功能:基于 xampp (环境）+HTML+mysql+PHP+JS+百度地图 API 实现武汉公交客流站点、线路、区域的查询显示和时空统计分析同时将结果可视化；</span></span></p><p>② <span style="color:#333333"><span style="font-size:12px">第二阶段仿真功能:基于考虑乘客行为的 JS 仿真算法，实现工作日、节假日、以及不同天气对公交线路、站点的候车客流、断面客流进行仿真并且实现结果二维可视化;</span></span></p><p>③ <span style="color:#333333"><span style="font-size:12px">第三阶段预测功能:基于历史客流数据统计，使用三次指数平滑预测算法，实现未来某一天指定线路、站点上下车客流的预测。</span></span></p><p></p><p></p>',
      isShow: true,
      label: '',
    },
    roleOfProject: {
      value: '主要成员',
      isShow: true,
      label: '',
    },
    experienceName: {
      value: '互联网+',
      isShow: true,
      label: '',
    },
    startTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    upToNow: {
      value: false,
      isShow: true,
      label: '',
    },
  },
];
export const initialSchoolProjectExpirenceValues = [
  {
    endTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    projectIntroduction: {
      value:
        '<p><span style="font-size:14px"><strong>工作内容:</strong></span></p><p>① <span style="color:#333333"><span style="font-size:12px">第一阶段查询统计分析功能:基于 xampp (环境）+HTML+mysql+PHP+JS+百度地图 API 实现武汉公交客流站点、线路、区域的查询显示和时空统计分析同时将结果可视化；</span></span></p><p>② <span style="color:#333333"><span style="font-size:12px">第二阶段仿真功能:基于考虑乘客行为的 JS 仿真算法，实现工作日、节假日、以及不同天气对公交线路、站点的候车客流、断面客流进行仿真并且实现结果二维可视化;</span></span></p><p>③ <span style="color:#333333"><span style="font-size:12px">第三阶段预测功能:基于历史客流数据统计，使用三次指数平滑预测算法，实现未来某一天指定线路、站点上下车客流的预测。</span></span></p><p></p><p></p>',
      isShow: true,
      label: '',
    },
    roleOfProject: {
      value: '文化部部长',
      isShow: true,
      label: '',
    },
    experienceName: {
      value: '华中科技大学校学生会',
      isShow: true,
      label: '',
    },
    startTime: {
      value: 0,
      isShow: true,
      label: '',
    },
    upToNow: {
      value: false,
      isShow: true,
      label: '',
    },
  },
];
export const initialPersonalHonorValues = [
  {
    honorDetails: {
      value: '2020 微信小程序应用开发大赛全国一等奖',
      isShow: true,
      label: '',
    },
  },
];
export const initialEvaluateValues = [
  {
    evaluateDetails: {
      value: '善于合作，勤奋热情，认真负责，动手和学习能力强，能吃苦，有恒心',
      isShow: true,
      label: '',
    },
  },
];
export const initialHobbyValues = [
  {
    hobbiesInfo: {
      value: '打篮球，跑步，读书，滑雪',
      isShow: true,
      label: '',
    },
  },
];
export const initialSelfSkillValues = [
  {
    skills: {
      value: '计算机二级，熟悉使用office办公软件，excel，ppt，word',
      isShow: true,
      label: '',
    },
  },
];
export const initCvModal = [
  {
    title: '基础信息',
    key: 'resumeBaseInfo',
    isShow: true,
    belong: REFORM_STEP.BASE,
    id: '0',
    desc: [
      '简历中有重要基本信息缺失，请务必填写姓名',
      '邮箱是简历上的重要基本信息，建议填写经常查看的常用邮箱',
      '电话是简历上的重要基本信息，请注意完整准确的填写',
      '简历文件名称注意根据投递职位修改，一般建议使用格式： 到岗时间-求职意向-姓名',
    ],
    link: 'base-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/dingdan.png?sign=6b68739e68ed930804016e78f88fd5fb&t=1652706294',
  },
  {
    title: '教育背景',
    key: 'resumeEducationInfo',
    isShow: true,
    belong: REFORM_STEP.EDUCATE,
    id: '1',
    desc: ['教育经历是简历上的必填信息，请添加教育经历模块'],
    link: 'educate-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/jiaoyu.png?sign=699b3908be07803a6ec615f00d69511d&t=1652706361',
  },
  {
    title: '求职岗位',
    key: 'resumeJobIntention',
    isShow: true,
    belong: REFORM_STEP.BASE,
    id: '2',
    desc: [
      '简历中有重要的基本信息缺失',
      '简历中有重要的基本信息缺失',
      '简历中有重要的基本信息缺失',
    ],
    link: '',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/gongzuotai.png?sign=47133ab51e9e9b6eadbccd9b90745d12&t=1652706333',
  },
  {
    title: '工作经验',
    key: 'resumeWorkExperienceInfo',
    isShow: true,
    belong: REFORM_STEP.JOBPOST,
    id: '3',
    desc: [
      '工作经历应该作为简历的主体内容，目前这部分篇幅和其他内容相比较少，建议再增加一些工作经历内容，或者删减其他部分内容',
    ],
    link: 'work-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/gerenzhongxinicon_shixixieyi.png?sign=5ea4d98ec7930c85592b38fb2194aa07&t=1652707101',
  },
  {
    title: '项目经验',
    key: 'resumeExperienceInfo',
    isShow: true,
    belong: REFORM_STEP.PROJECT,
    id: '4',
    desc: [
      '项目经验应该作为简历的主体内容，目前这部分篇幅和其他内容相比较少，建议再增加一些工作经历内容，或者删减其他部分内容',
    ],
    link: 'project-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/xiangmu.png?sign=a4e8598fb46566f216e5c60e1129b43f&t=1652706966',
  },
  {
    title: '实习经验',
    key: 'internshipExperience',
    isShow: true,
    desc: [
      '实习经验应该作为简历的主体内容，目前这部分篇幅和其他内容相比较少，建议再增加一些工作经历内容，或者删减其他部分内容',
    ],
    belong: REFORM_STEP.INTERSHIP,
    id: '5',
    link: 'intership-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/xiangmujingyan.png?sign=3e490c29fd333ab2198bf6ae42f41d85&t=1652706984',
  },
  {
    title: '校园经历',
    key: 'schoolExperience',
    isShow: true,
    belong: REFORM_STEP.SCHOOL,
    desc: [
      '校园经历应该作为简历的主体内容，目前这部分篇幅和其他内容相比较少，建议再增加一些工作经历内容，或者删减其他部分内容',
    ],
    id: '6',
    link: 'school-project-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/gongzuoliushezhi.png?sign=f47dbf5142d1b7d150755fb439da564a&t=1652707190',
  },
  {
    title: '专业技能',
    key: 'resumePersonalSkillInfo',
    desc: ['专业技能作为', '简历中有重要的基本信息缺失', '简历中有重要的基本信息缺失'],
    isShow: true,
    belong: REFORM_STEP.SELFSKILL,
    id: '7',
    link: 'skills-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/jinengzhengshu.png?sign=a363f0d4f52049b977dab7645eed85d2&t=1652706402',
  },
  {
    title: '个人荣誉',
    key: 'resumePersonHonorInfo',
    isShow: true,
    belong: REFORM_STEP.HONOR,
    desc: ['展示个人在各个领域的荣誉，可以有力的证明个人的实力'],
    id: '8',
    link: 'honor-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/renzheng.png?sign=c1cd512427f8ab5c4e574ec58053a767&t=1652706423',
  },
  {
    title: '个人评价',
    key: 'resumePersonEvaluateInfo',
    isShow: true,
    belong: REFORM_STEP.EVALUATE,
    id: '9',
    desc: ['填写个人评价，可以更好展示个人的综合实力'],
    link: 'evalate-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_zlorgv28m3/zaizhaorenshu.png?sign=89e0da481bbed29ec068832ab74a6ce3&t=1652706500',
  },
  {
    title: '兴趣爱好',
    key: 'hobbiesInfo',
    isShow: true,
    belong: REFORM_STEP.HOBBY,
    desc: ['填写个人兴趣爱好，可以更好展示个人的综合实力'],
    id: '10',
    link: 'evalate-info',
    iconUrl:
      'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/icon_f95oujphwnd/xingquaihao.png?sign=4a8febbc27fd1ed91e670bc30b11fd99&t=1652706999',
  },
];

export const industryOptions = [
  {
    value: 1,
    label: '互联网/IT',
  },
  {
    value: 2,
    label: '咨询/金融/四大/商业分析',
  },
  {
    value: 3,
    label: '新媒体',
  },
  {
    value: 4,
    label: '教育/培训',
  },
  {
    value: 5,
    label: '房地产/建筑',
  },
  {
    value: 6,
    label: '其他',
  },
];
export const isExpeditedOptions = [
  {
    value: 1,
    label: '正常(3天内反馈)',
  },
  {
    value: 2,
    label: '加急(5小时内反馈)',
  },
];
export const isStudyAbroadOptions = [
  {
    value: 1,
    label: '无海外留学经验',
  },
  {
    value: 2,
    label: '有海外留学经验',
  },
];
export const luanguageOptions = [
  {
    value: 1,
    label: '简体中文',
  },
];
export const experienceOptions = [
  {
    value: 1,
    label: '无求职经验',
  },
  {
    value: 2,
    label: '有求职经验',
  },
];

/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2023-07-30 15:38:01
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-06 21:50:33
 * @FilePath: /greenet-resume-project/src/componets/VipCard/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { Avatar, Modal, Image, Spin,  } from 'antd';
import { LoadingOutlined, RedoOutlined, UserOutlined } from '@ant-design/icons';
import QRCodeCanvas from 'qrcode.react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import './index.less';
import { useHistory } from 'react-router-dom';
import { ORDERSTATUS, QRCODESTATUS, memberShipData } from 'pages/home/pages/common';
import { useState } from 'react';
import {
  WechatPayControllerCreateVIPNativePOST,
  WechatPayControllerQueryByOutTradeNoGet,
} from 'api/wei-xin-pay-controller';
import wepayIconUrl from 'pages/home/pages/ServiceSelection/components/pay.png';
import message from 'common/message';
import { useRequest, useUpdateEffect } from 'ahooks';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
interface PropsType {
  isVisiable: boolean;
  setIsVisiable: (isVisiable: boolean) => void;
}
const VipCard = (props: PropsType) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const history = useHistory();
  const { isVisiable = true, setIsVisiable } = props;
  const userBaseInfo = useSelector((state) => state.global.userBaseInfo);
  const [QrcodeStatus, setQrcodeStatus] = useState(QRCODESTATUS.LOADING);
  const [QrcodeValue, setQrcodeValue] = useState('');
  const [activeId, setActiveId] = useState<number>(1);


 
  const payConfirm = () => {
    if (isSuccess) {
      history.push(`/personalCenter?activeId=${activeId}`);
    } else {
      message.error('订单支付未完成，请完成支付再试');
    }
  };

  const getWeChatPayQRCode = async (activeId: number) => {
    try {
      const data = await WechatPayControllerCreateVIPNativePOST({
        total_fee: memberShipData.filter((item) => item.id === activeId)[0].fee,
        body: memberShipData.filter((item) => item.id === activeId)[0].title,
        vipType: activeId,
      });
      if (data.result === 1) {
        setQrcodeValue(data.data.QrCode);
        setQrcodeStatus(QRCODESTATUS.SUCCESS);
        run(data.data.out_trade_no);
        setTimeout(() => {
          setQrcodeStatus(QRCODESTATUS.TIMEOUT);
        }, 1000 * 60 * 10);
      }
    } catch (e: any) {
      console.log('支付二维码获取失败', e.error_message);
      message.error('支付二维码获取失败', e.error_message);
      setQrcodeStatus(QRCODESTATUS.ERROR);
    }
  };
  const { run, cancel } = useRequest(WechatPayControllerQueryByOutTradeNoGet, {
    manual: true,
    pollingInterval: 500,
    onSuccess: (data, param) => {
      if (data.data.orderStatus === ORDERSTATUS.SUCCESS) {
        cancel();
        message.success('支付成功');
        setIsSuccess(true);
      }
    },
    onError: (e: any) => {
      cancel();
      message.error(e.error_msg, '支付失败');
    },
  });
  useUpdateEffect(() => {
    isVisiable && getWeChatPayQRCode(activeId);
    !isVisiable && cancel();
  }, [isVisiable, activeId]);
 
  const head = (
    <div className="vip-model-header">
      <div className="vip-modal-header-v">
        <div className="el-image">
          <Avatar
            style={{ width: 48, height: 48 }}
            icon={<UserOutlined />}
            src={
              <Image
                src="https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/avatar.png"
                preview={false}
                style={{ width: 48 }}
              />
            }
          />
        </div>
        <div className="tw-text-whitle">
          <div className="tw-text-name"> {userBaseInfo?.username ?? '优加DA-000用户'}</div>
          <div className="tw-text-desc">亲爱优优你还不是会员，开通立享特权</div>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      footer={null}
      open={isVisiable}
      onCancel={() => {
        setIsVisiable(false);
      }}
      title={head}
      className="vip-modal"
      width={838}
      centered
    >
      <div className="vip-dialog-body">
      <div className="vip-content-left">
          <div className="vip-options">
            {memberShipData.map((item) => {
              return (
                <div
                  className={classnames('vip-options-item', {
                    active: item.id === activeId,
                  })}
                  key={item.id}
                  onClick={() => {
                    setActiveId(item.id);
                    console.log(1);
                  }}
                >
                  {item.id === 1 && (
                    <>
                      <div className="position-bottom">限时立减100</div>
                      <div className="recommend-final-vip-layout">推荐</div>
                    </>
                  )}
                  <p
                    className={classnames('time', {
                      active: item.id === activeId,
                    })}
                  >
                    {item.title}
                  </p>
                  <div className="pic-container">
                    <span className="pic-tip">¥</span>
                    <span className="pic-text">{item.fee}</span>
                  </div>
                  <del className="old-pic">¥{item.preFee}</del>
                  {item.id === 4 && (
                    <div
                      className={classnames('pic-explain', {
                        active: item.id === activeId,
                      })}
                    >
                      终身会员+学长1V1简历优化
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="vip-content-left-bottom">
            {activeId !== 4 ? (
              <div className="vip-icon-container">
                {memberShipData[activeId - 1].content.map((item) => {
                  return (
                    <div
                      className={classnames('kind', {
                        opcity: activeId !== 1 && item.index > 7,
                      })}
                      key={item.index}
                    >
                      <div className="div-img">
                        <img
                          src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${item.index}.png`}
                          alt="1"
                        />
                      </div>
                      <p>{item.text}</p>
                      <span>{item.icon}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="svip-container">
                <div className="left">
                  <img
                    src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${12}.png`}
                    alt=""
                  />
                </div>
                <div className="and">
                  <img
                    src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${13}.png`}
                    alt=""
                  />
                </div>
                <div className="right">
                  <img
                    src={`https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/vip/${14}.png`}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="vip-content-right">
          <div className="pay-fixed">
            <div className="pay-title">扫码支付，解锁更多权益</div>
          
              <>
                <div className="pay-QRcode">
                  <div className="Qrcode">
                    <QRCodeCanvas
                      value={QrcodeValue}
                      size={120}
                      bgColor={'#ffffff'}
                      fgColor={'#000000'}
                      level={'M'}
                      includeMargin={false}
                      imageSettings={{
                        src: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/%E7%BD%91%E7%AB%99logo/logo.JPG?sign=bcb9d07d10304f151e62e7146bbd40b6&t=1648001975',
                        x: undefined,
                        y: undefined,
                        height: 24,
                        width: 24,
                        excavate: true,
                      }}
                    />
                    {QrcodeStatus !== QRCODESTATUS.SUCCESS && (
                      <div
                        className="Qr-mask"
                        onClick={() => {
                          QrcodeStatus !== QRCODESTATUS.LOADING &&
                            isVisiable &&
                            getWeChatPayQRCode(activeId);
                        }}
                      >
                        {QrcodeStatus === QRCODESTATUS.LOADING && (
                          <Spin indicator={antIcon} style={{ color: '#ff3d3d' }}></Spin>
                        )}

                        {QrcodeStatus === QRCODESTATUS.ERROR && (
                          <>
                            <RedoOutlined
                              style={{ fontSize: 24, color: '#ff3d3d', display: 'inline-block' }}
                            />
                            <span
                              style={{ fontSize: 15, color: '#ff3d3d', display: 'inline-block' }}
                            >
                              二维码错误
                            </span>
                          </>
                        )}
                        {QrcodeStatus === QRCODESTATUS.TIMEOUT && (
                          <>
                            <RedoOutlined
                              style={{ fontSize: 24, color: '#ff3d3d', display: 'inline-block' }}
                            />
                            <span
                              style={{ fontSize: 15, color: '#ff3d3d', display: 'inline-block' }}
                            >
                              二维码过期
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="pay-type">
                  <div className="pay-type-img">
                    <p className="pay-icon">
                      <img src={wepayIconUrl} width={24} alt="1" />
                    </p>
                  </div>
                  <p className="pay-type-img">使用微信，扫码支付</p>
                </div>
                <div className="pay-price">
                  ¥ {memberShipData.filter((item) => item.id === activeId)[0].fee}
                </div>
                <div className="pay-comps">
                  <a href="./">使用优惠券</a>
                </div>
                <div className="pay-success-btn">
                  <button
                    onClick={() => {
                      payConfirm();
                    }}
                  >
                    支付成功
                  </button>
                </div>
              </>
            
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VipCard;

import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import modify from 'common/lottie/modify.json';

import './index.less';

const Header = () => {
  const history = useHistory();
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: modify,
  };

  return (
    <div className="header-content pr">
      <div className="header1">
        <div className="tit1">你为什么需要一份好的简历</div>
        <p>
          企业 hr 每天查看
          <span>上千份</span>
          各式各样的简历
        </p>
        <p>
          平均每份简历查看
          <span> 6 </span>
          秒钟
        </p>
        <p>
          只有一份
          <span>规范、专业</span>
          的简历，才能引起 hr 注意
        </p>
        <p>
          优加实习已帮
          <span> 12,863 </span>
          位候选人优化简历，通过率提高
          <span> 90%</span>
        </p>
        <button
          className="indexButton"
          onClick={() => {
            history.push('/choseType');
            localStorage.setItem('order', 1);
          }}
        >
          立即体验
        </button>
      </div>
      <div className="header_img">
        <Lottie options={defaultOptions} width={700} />
      </div>
    </div>
  );
};

export default Header;

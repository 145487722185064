import React from 'react';
import { Select } from 'antd';
import './index.less';

const { Option } = Select;
const YSelect = ({ placeholder = '请输入', options = [], onChange, value }) => {
  //   console.log(value);
  return (
    <div className="y-select">
      <Select
        value={value}
        onChange={onChange}
        placement="bottomLeft"
        placeholder={placeholder}
        className="Yselect"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {options.map((e) => {
          return (
            <Option value={e.value} key={e.value}>
              {e.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
export default YSelect;

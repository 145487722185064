/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2022-09-05 20:20:11
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-06 18:46:48
 * @FilePath: /greenet-resume-project/src/reduxStroe/global.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { userBaseInfoUsingGET } from 'api/user-controller';
import { IGlobalState } from './interface';
import { WechatPayControllerQueryAccountGET } from 'api/wei-xin-pay-controller';

const initialState: IGlobalState = {
  userBaseInfo: undefined,
  queryAccount: undefined,
  loginStatus: undefined,
};
export const globalStore = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setUserBaseInfo: (state, action) => {
      state.userBaseInfo = action.payload;
    },
    setQueryAccount: (state, action) => {
      state.queryAccount = action.payload;
    },
  },
});
export const globalAction = globalStore.actions;

export default globalStore.reducer;

export const asyncFetchBaseUserInfo =
  (params: { success?: () => any; fail?: () => any; finally?: () => any }) =>
  (dispatch: Dispatch<any>) => {
    userBaseInfoUsingGET()
      .then((res) => {
        dispatch(globalAction.setUserBaseInfo(res.data));
      })
      .catch((e) => {
        params.fail?.();
      })
      .finally(() => {
        params.finally?.();
      });
  };
export const asyncFetchQueryAccount =
  (params: { success?: () => any; fail?: () => any; finally?: () => any }) =>
  (dispatch: Dispatch<any>) => {
    WechatPayControllerQueryAccountGET()
      .then((res) => {
        dispatch(globalAction.setQueryAccount(res.data));
      })
      .catch((e) => {
        params.fail?.();
      })
      .finally(() => {
        params.finally?.();
      });
  };

import { useMount } from 'ahooks';
import React, { FC } from 'react';
import RecruitInfoBanner from './components/Banner';
import RecruitInfoItem from './components/RecruitInfoItem';
// import RecruitTitle from './components/RecruitTitle';

import './index.less';

const data = {
  
  recruitinfo: [
    {
      name: '字节跳动',
      logo: 'https://uploadfiles.nowcoder.com/images/20220808/330699344_1659946449229/1FC8B2F316140F82B3F02B371E5E6B3A',
      webSite:'https://jobs.toutiao.com/s/k6F397w',
      pushCode: '2E54XY2',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '锐捷网络',
      logo: 'https://uploadfiles.nowcoder.com/files/20221028/1030035845_1666949017907/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://www.ruijie.com.cn/campus-recruiting/',
      pushCode: 'DScpG3QT',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: 'TCL',
      logo: 'http://campus.tcl.com/image/logo1.png',
      webSite: 'http://campus.tcl.com/',
      state: 1,
      pushCode: 'wsztcd',
      updateDate: '2023-01-16',
    },
    {
      name: '滴滴',
      logo: 'https://uploadfiles.nowcoder.com/files/20221017/621313650_1666004314939/didi.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://campus.didiglobal.com/campus_apply/didiglobal/6223#/',
      pushCode: 'DSCuWZt4',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '理想汽车',
      logo: 'https://uploadfiles.nowcoder.com/files/20220211/127456592_1644572366024/64D3B5811C97FF69FE9F9874DE5BE1F11?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://li.jobs.feishu.cn/referral/campus/position?token=MzsxNjcyMzkyNDIyMDE1OzcxMTcwNDI0NTEzMTk1Mzc2Njc7MA',
      pushCode: '4JZBW6Q',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '京东',
      logo: 'https://uploadfiles.nowcoder.com/files/20221009/1030035845_1665311754589/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://zhaopin.jd.com/home',
      pushCode: 'C38L6',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '用友（补录）',
      logo: 'https://uploadfiles.nowcoder.com/images/20221123/335747682_1669190617530/A209D729661EE2A39B4DD13CB50775BA?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://career.yonyou.com/SU61108d5b0dcad4106ff62e80/pb/index.html#/',
      pushCode: 'NdQkJryk',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '海尔',
      logo: 'https://uploadfiles.nowcoder.com/files/20220118/173362216_1642496165794/999991354_1552037921664_%E6%B5%B7%E5%B0%943.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'http://maker.haier.net/client/campusmobile/activity/id/19.html?inviter_code=NUkwbVJEd0NmYjA9',
      pushCode: '点击链接即可',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '蔚来',
      logo: 'https://lf3-atsx-tob.feishucdn.com/obj/static-atsx-online-ee-tob/b6c592b7e6086da5b002329393f37aa3/564f952ddbeccd416f6f15ef36d15337d68c7bd9dfbbbcd6366079b8734b023c.png',
      webSite: 'https://nio.jobs.feishu.cn/referral/campus/position/?token=NTsxNjY5NTIwODM3OTI4OzcxNDI5NTc1MzM3MzI3NjU3MjQ7NzE1NjkyMzg3OTk2NTA2NzU1Nw',
      pushCode: '7BVNVQF',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '平安财险',
      logo: 'https://uploadfiles.nowcoder.com/files/20220118/1030032973_1642495607013/999991354_1571297313693_%E5%B9%B3%E5%AE%89%E4%BA%BA%E5%AF%BF3-120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://campus.pingan.com/pacxzb',
      pushCode: '276486',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '格力',
      logo: 'https://uploadfiles.nowcoder.com/files/20221018/1030035845_1666075104773/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://gree.m.zhiye.com/index.html',
      pushCode: 'EVBJBB',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '波克城市',
      logo: 'https://uploadfiles.nowcoder.com/images/20220407/650353352_1649338907772/E6B6034960644BBF518DEAE8BE4CE48B?x-oss-process=image%2Fresize%2Cw_72%2Ch_72%2Cm_mfit',
      webSite: 'https://boke.jobs.feishu.cn/referral/m/position?token=MzsxNjcwNDY1OTExMDI5OzcwNjk2MzcyNjY1NjcxMTg4NDk7MA',
      pushCode: '1TSHM8R',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: 'SHEIN',
      logo: 'https://uploadfiles.nowcoder.com/files/20220129/303407344_1643438189014/SHEIN.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://app.mokahr.com/campus_apply/shein/2932?sourceToken=469afc749b2b3ec4ca9f28a693603f5f#/',
      pushCode: 'NTAKYRu',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '帆软',
      logo: 'https://uploadfiles.nowcoder.com/files/20181107/63_1541589923490_CgpFT1kBjxiAdosTAAAOdgfjfIw119.jpg?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://join.fanruan.com/',
      pushCode: 'Do28w3g',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '烽火通信',
      logo: 'https://public-cdn.mokahr.com/whfhtx/98e741a8-22c8-49dc-b011-8cf1404e34f6.png',
      webSite: 'https://app.mokahr.com/campus_apply/whfhtx/73922#/',
      pushCode: 'NTAQ0T6',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '深信服',
      logo: 'https://uploadfiles.nowcoder.com/files/20221028/1030035845_1666949212802/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://app.mokahr.com/campus_apply/sangfor/6146?sourceToken=4f6f5591267ffd0bd83457a7b7885e6a#/',
      pushCode: 'NTAL7Qj',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '拼多多（管培）',
      logo: 'https://5b0988e595225.cdn.sohucs.com/images/20190422/5708ee6fa6c74a1eb6cefb82ee268da1.jpeg',
      webSite: 'https://careers.pinduoduo.com/campus/grad',
      pushCode: 'F0tkRp3FFi',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '自如集团',
      logo: 'https://uploadfiles.nowcoder.com/files/20221009/1030035845_1665313030429/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://jinshuju.net/f/pWFYfY',
      pushCode: '点击链接即可',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '阿里巴巴',
      logo: 'https://uploadfiles.nowcoder.com/files/20181102/4575098_1541147538969_826546_1499420128657_7.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://talent.alibaba.com/campus/qrcode/home?code=fd%2Fh2tkWcNE2CTJDAbeZIg%3D%3D',
      pushCode: '点击链接即可',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '快手',
      logo: 'https://uploadfiles.nowcoder.com/files/20220801/502184083_1659341979487/%E7%89%9B%E5%AE%A2-%E6%A0%A1%E6%8B%9B%E6%97%A5%E7%A8%8B%E7%BD%AE%E9%A1%B6.jpg',
      webSite: 'https://campus.kuaishou.cn/#/campus/index',
      pushCode: 'eeFWRvarm',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '蚂蚁集团',
      logo: 'https://img.zcool.cn/community/0147235f48ac1411013e3187614ed3.jpg',
      webSite:
        'https://hrrecommend.antgroup.com/guide.html?code=lDvsnetqb4FRwh_taNYl_ThHBpBssZy_AUZh_9_59f8%3D',
      pushCode: '点击链接即可',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '海信',
      logo: 'https://uploadfiles.nowcoder.com/files/20220421/303407344_1650513663448/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://hisense.zhiye.com/jihui',
      pushCode: 'IV3MKJ',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '数字广东',
      logo: 'https://uploadfiles.nowcoder.com/files/20220930/594799920_1664539151789/%E6%95%B0%E5%AD%97%E5%B9%BF%E4%B8%9Clogo.jpg',
      webSite: 'http://digitalgd.wintalent.net/wt/Digitalgd/web/index ',
      pushCode: 'ainukw',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '宁德时代',
      logo: 'https://public-cdn.mokahr.com/catlhr/d9b55bc7-22ab-4294-98c3-7a1315f34b14.png',
      webSite: 'https://app.mokahr.com/m/campus_apply/catlhr/73943?recommendCode=DSfHYtXs#/jobs',
      pushCode: 'DSfHYtXs',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '禾赛科技',
      logo: 'https://uploadfiles.nowcoder.com/files/20220621/303407344_1655812408728/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://kwh0jtf778.jobs.feishu.cn/s/6538cKH',
      pushCode: 'YC4UK4S',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '远景',
      logo: 'https://uploadfiles.nowcoder.com/images/20220302/894790138_1646194570896/D6969C98BC2C0947BA4D4AA0CDF33902',
      webSite: 'https://app.mokahr.com/campus-recruitment/envisiongroup/43123#/',
      pushCode: 'NTAMG5R',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '贝壳找房',
      logo: 'https://uploadfiles.nowcoder.com/files/20180820/999991342_1534747789658_60x60-2.png',
      webSite: 'http://campus.ke.com/',
      pushCode: '26660424',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '华宝新能源',
      logo: 'https://career-m.hello-tech.com/images/logo.png',
      webSite: 'https://career.hello-tech.com/index',
      pushCode: 'ESVM2S',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '云智研发',
      logo: 'https://uploadfiles.nowcoder.com/files/20220118/1030032973_1642494119066/-%E5%9C%86%E5%BD%A9-120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://app-tc.mokahr.com/campus-recruitment/csig/20001#/',
      state: 1,
      pushCode: 'NTAAKTk',
      updateDate: '2023-01-16',
    },
    {
      name: '游族网络',
      logo: 'https://uploadfiles.nowcoder.com/images/20220726/3675921_1658847220604/D6969C98BC2C0947BA4D4AA0CDF33902?x-oss-process=image%2Fresize%2Cw_72%2Ch_72%2Cm_mfit',
      webSite: 'https://app.mokahr.com/apply/yoozoo/3729#/home',
      pushCode: 'NTAL8aM',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '蓝禾科技',
      logo: 'https://img5.tianyancha.com/logo/lll/ee0254d528733e0c9468f2360b21ed68.png@!f_200x200',
      webSite: 'https://jinshuju.net/f/ojkwMp',
      pushCode: 'LHwbf666',
      state: 1,
      updateDate: '2023-01-16',
    }, 
    {
      name: '吉利集团',
      logo: 'https://upload.wikimedia.org/wikipedia/zh/thumb/9/99/GEELY_AUTO_LOGO.png/440px-GEELY_AUTO_LOGO.png',
      webSite: 'https://campus.geely.com/hcm-web/#/job/campus',
      pushCode: 'msx21DwJ',
      state: 1,
      updateDate: '2023-01-16',
    }, 
    {
      name: '完美世界',
      logo: 'https://uploadfiles.nowcoder.com/files/20220211/127456592_1644569585451/301459_1602232934047_C31DCFA4DD72CDFFBE89361376D01C231?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://recruit.games.wanmei.com/campus-recruitment/pwrd/45131/#/',
      pushCode: 'NTAHXba',
      state: 1,
      updateDate: '2023-01-16',
    }, 
    {
      name: '菲尼克斯',
      logo: 'http://campus.51job.com/fnks2023/images/icon_logo.png',
      webSite: 'https://mp.weixin.qq.com/s/B7s0WkGZetJaPnRs6KwCNg',
      pushCode: 'DSMD96As',
      state: 1,
      updateDate: '2023-01-16',
    }, 
    {
      name: '蓝月亮',
      logo: 'https://uploadfiles.nowcoder.com/files/20221028/1030035845_1666947947995/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://talent.bluemoon.com.cn/campus',
      pushCode: 'BMWH12',
      state: 1,
      updateDate: '2023-01-16',
    }, 
    {
      name: '荣耀',
      logo: 'https://5b0988e595225.cdn.sohucs.com/images/20181129/b4bcef7d5a704faeaba06bd87d666f6b.jpeg',
      webSite:'https://career.hihonor.com/SU5ff5e1f99b0d78e6f4292d4e/mc/position/campus?acotycoCode=zrzbmd ',
      pushCode: 'nzfwzr',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '小米',
      logo: 'https://uploadfiles.nowcoder.com/files/20220105/164872_1641354357948/%E5%B0%8F%E7%B1%B3.jpg?x-oss-process=image%2Fresize%2Cw_96%2Ch_96%2Cm_fill',
      webSite: 'https://hr.xiaomi.com/campus',
      state: 1,
      pushCode: 'NTAM6LG',
      updateDate: '2023-01-16',
    },
    {
      name: '美团',
      logo: 'https://uploadfiles.nowcoder.com/files/20220518/1030032971_1652860826659/meituan120.png',
      webSite: 'https://campus.meituan.com',
      pushCode: 'yADswEu',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '米哈游',
      logo: 'https://uploadfiles.nowcoder.com/files/20220228/173362216_1646032551127/%E5%9C%86%E5%BD%A9120x120.png',
      webSite: 'https://campus.mihayo.com/#/',
      pushCode: 'A3LDC',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '携程',
      logo: 'https://uploadfiles.nowcoder.com/files/20220221/303407344_1645416028777/%E5%9C%86%E5%BD%A9120x120.png',
      webSite: 'https://campus.ctrip.com/recommendation-apply/trip/38951#/',
      pushCode: 'NTAH5kj ',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '华为',
      logo: 'https://uploadfiles.nowcoder.com/images/20220901/112951718_1662011483868/FECD76F09C4EFFA7102ECDBC1795FB3B?x-oss-process=image%2Fresize%2Cw_72%2Ch_72%2Cm_mfit',
      webSite: 'http://career.huawei.com/reccampportal/portal5/index.html',
      pushCode: 'VX：MXM13641044045',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '百度',
      logo: 'https://uploadfiles.nowcoder.com/files/20220803/621313650_1659512669691/logo.jpg',
      webSite: 'https://talent.baidu.com/jobs/list?recommendCode=ISVGBJ',
      pushCode: 'VX：suoni123088',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '联想',
      logo: 'https://uploadfiles.nowcoder.com/images/20190315/310938_1552636340640_1BB87D41D15FE27B500A4BFCDE01BB0E',
      webSite: 'https://talent.lenovo.com.cn/',
      pushCode: 'WWY2023',
      state: 1,
      updateDate: '2023-01-16',
    },
    {
      name: '海康威视',
      logo: 'https://uploadfiles.nowcoder.com/files/20220714/841502154_1657768826612/%E5%9C%86%E5%BD%A9120x120.png?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_fill',
      webSite: 'https://talent.bluemoon.com.cn/campus',
      pushCode: 'YYTH5Q',
      state: 2,
      updateDate: '2023-01-16',
    }
  ],
};
const RecruitInfo: FC = () => {
  useMount(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <div className="recruit-page">
      <div className="recruit-info">
        <RecruitInfoBanner />
        <RecruitInfoItem recruitInfo={data} />
        <div></div>
      </div>
    </div>
  );
};
export default RecruitInfo;

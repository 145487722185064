import React from 'react';
import './index.less';
import BannerAnim from 'rc-banner-anim';

const { Element } = BannerAnim;
const UsersSwiper = () => {
  return (
    <div className="discourse">
      <div className="discourse-title">看看使用过的人怎么说</div>
      <div className="discourse-swiper">
        <BannerAnim style={{ position: 'initial' }}>
          <Element
            key="comment1"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="userComments">
              <img
                src={'https://files.wondercv.com/pc/mentor/discourse_1.png'}
                style={{ width: '1282px', height: '846px' }}
                alt="true"
              />
            </div>
          </Element>
          <Element
            key="comment2"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="userComments">
              <img
                src={'https://files.wondercv.com/pc/mentor/discourse_2.png'}
                style={{ width: '1282px', height: '846px' }}
                alt="true"
              />
            </div>
          </Element>
        </BannerAnim>
      </div>
    </div>
  );
};

export default UsersSwiper;

import React, { FC } from "react";
import './index.less'


const AddNewResume:FC = ()=>{
    const munes = document.querySelectorAll("div.menu-list")
    console.log(munes)

    const resumeClass = [
        {
            avtor:'https://files.wondercv.com/PC/cvs/resume_icon/greenhand.png',
            title:'零经验实习',
            desc:'学生找实习，第一次工作，没有实习经历的同学'
        },
        {
            avtor:'https://files.wondercv.com/PC/cvs/resume_icon/student.png',
            title:'学生求职',
            desc:'学生找实习或全职工作，有一定实习经历的同学'
        },
        {
            avtor:'	https://files.wondercv.com/PC/cvs/resume_icon/pm.png',
            title:'申请研究生',
            desc:'学生申请国内及海外的研究生，或考研复试时使用'
        },
        {
            avtor:'https://files.wondercv.com/PC/cvs/resume_icon/postgraduate.png',
            title:'经典实习',
            desc:'工作经验1年以上，想变动工作的人士，适用于全行业'
        },
        {
            avtor:'https://files.wondercv.com/PC/cvs/resume_icon/work.png',
            title:'投行咨询',
            desc:'寻求投行，咨询，四大或外企工作岗位的人士'
        },
        {
            avtor:'	https://files.wondercv.com/PC/cvs/resume_icon/overseas.png',
            title:'产品经理',
            desc:'申请互联网产品相关岗位，有针对性的案例和引导'
        },
        {
            avtor:'https://files.wondercv.com/PC/cvs/resume_icon/developer.png',
            title:'程序员',
            desc:'互联网行业的技术求职者，找全职工作、跳槽时使用'
        },
        {
            avtor:'https://files.wondercv.com/PC/cvs/resume_icon/banking.png',
            title:'本科留学申请',
            desc:'申请海外留学，有针对性的英文案例和指导'
        }
    ]

    return (
        <div className="alert-main">
            <div className="box template">
                <div className="menu-list">
                    {
                        resumeClass.map((item,index)=>{
                            return (
                                <React.Fragment>
                                    <div className="menu" key={index} >
                                        <img src={item.avtor} alt="true"/>
                                        <p className="title">{item.title}</p>
                                        <p className="desc">{item.desc}</p>
                                        <div className="btn">
                                            <button>中文模板</button>
                                            {index > 4 ? '' : <button>英文模板</button>}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                <div className="template-more-btn">查看更多简历模板</div>
            </div>
        </div>
    )
}
export default AddNewResume
import React, { FC } from "react";
import './index.less'

const ImportResume:FC = () => {

    return(
        <div className="alert-main">
            <div className="box import-resume">
                <div className="tips">
                    <p className="title">拖曳简历到此区域</p>
                    <p className="mes">支持Word、PDF、PNG或JPG等格式文件</p>
                </div>
                <div className="upload-resume">
                    <div className="ele-upload ">
                        <div className="ele-upload-dragger">
                            <button className="upload-btn">上传简历</button>
                        </div>
                        <input type="file" name="file" className="ele-upload-input" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImportResume
import React from 'react';
import { Collapse } from 'antd';
const Question = () => {
  return (
    <div className="service-other">
      <div className="service-info-title">
        <div className="num">3</div>
        <div>服务流程</div>
      </div>

      <div className="service-other-process">
        <img src="https://files.wondercv.com/PC/service/service_intern_liucheng.png" alt=""></img>
      </div>

      <div className="service-info-title">
        <div className="num">4</div>
        <div>如果你有以下疑问</div>
      </div>
      <div className="service-other-question">
        <Collapse defaultActiveKey={['']} ghost expandIcon={() => ''}>
          <Collapse.Panel
            header="1.导师辅导的内容包括哪些？
"
            key="1"
          >
            <div className="question-desc">
              <p>
                优加实习一位<span className="red-text">10000+</span>
                的在校大学生提供实习简历优化、模拟面试、职业规划等服务，
                <span className="red-text">90%</span>
                的客户都成功获取Offer，在过程中优加总结出了一套大幅提高简历成功率的方法论。每一位成功导师皆有5-10年从业经验，并经过平台筛选，确保简历修改的标准。{' '}
              </p>
              <br />
              <p>
                {' '}
                自<span className="red-text">2021年6月起</span>
                ，导师服务已全面升级，三大服务内容打造一站式绝佳体验，服务内容包括：
              </p>
              <br />{' '}
              <p>1. 1v1 简历修改：根据求职意向全方面优化简历的各个维度，并给予详细的修改意见； </p>
              <br />{' '}
              <p>
                2.
                匹配度解析：帮助候选人拆解和理解岗位真实要求，挖掘和突出个人优势，结合用人方能力模型对用户简历进行个性化定制
              </p>
              <br />{' '}
              <p>
                3. 电话咨询：针对购买在职版套餐的求职者，服务会包含一次30分钟的微信电话交流服务。{' '}
              </p>
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="2.下单前注意事项？" key="2">
            <div className="question-desc">
              <p>
                {' '}
                <span className="red-text">
                  <b>【1. 不作假】：</b>
                </span>{' '}
                导师辅导服务提供的每一份简历都是最大化体现候选人的亮点进而增加候选人的竞争力，但是必须是真实可信的。根据经验，简历内容超出实际能力的候选人会在面试环节被刷掉。求职要量力而为，最合适的Offer才是最好的Offer，切勿急功近利。{' '}
              </p>{' '}
              <br />{' '}
              <p>
                <span className="red-text">
                  <b>【2. 沟通内容】：</b>
                </span>{' '}
                为了提供准确、有效的求职建议，下单前会要求提供目标岗位的JD（职位描述）。
              </p>
              <br />
              <p>
                <span className="red-text">
                  <b>【3. 其他帮助】：</b>
                </span>{' '}
                如果有其他需要，如面试辅导、职业规划、行业认知等问题请与导师沟通，导师同意接单后您需额外支付相应服务费用后再进行服务。
              </p>
              <br />
              <p>
                <span className="red-text">
                  <b>【4. 不覆盖范畴】：</b>
                </span>
                导师服务只针对求职简历辅导。考研、出国、MBA、申请奖项等其他简历使用场景均不属于服务范围内，无法保证此类场景的辅导效果，也无法针对简历以外的文书进行辅导
              </p>{' '}
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="3.导师由谁来担任？" key="3">
            <div className="question-desc">
              超级简历的导师团队来自高盛、美世咨询、四大、谷歌、BAT、各大 500 强的顶级名企，拥有至少
              5 年以上工作经验，并且负责或参与企业招聘流程。
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="4.多长时间会收到修改回复？" key="4">
            <div className="question-desc">
              <p>
                导师辅导首轮反馈会在 3 个工作日完成（实习版 3
                个工作日交付终版），并通过微信推送通知。
              </p>{' '}
              <br />
              <p>
                {' '}
                <span className="red-text">
                  <b>【实习版】 </b>
                </span>
                包括 1 轮简历修改反馈；
              </p>{' '}
              <br />
              <p>
                <span className="red-text">
                  <b>【校招版】 </b>
                </span>{' '}
                包括 10 天内多轮（每轮 3 个工作日）简历修改、添加导师微信沟通；
              </p>{' '}
              <br />
              <p>
                <span className="red-text">
                  <b>【在职版】 </b>
                </span>
                【在职版】包括 10 天内多轮（每轮 3 个工作日）简历修改、添加导师微信沟通以及 1
                次微信语音沟通。{' '}
              </p>{' '}
              <br />
              您还可以根据需求选择面试辅导 & 职业生涯规划服务。校招版 &
              在职版多轮辅导在导师交付初稿后，需要您在7天内提交修改，超过7天未提交修改，导师辅导将自动结束。
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="5.我想要加急怎么办？" key="5">
            <p>
              您可以在下单前选择是否加急，具体请参照以下表格： 套餐/周期 普通 加急 实习版 3工作日
              24小时 校招版 3工作日 24小时 在职版 3工作日 24小时
              根据套餐版本及行业选择不同，加急费用为服务基本费用上加价¥100～¥200元不等
              所有修改时间均为工作日，不包含周末和节假日。 如有特殊需求请联系客服沟通。
            </p>
          </Collapse.Panel>
          <Collapse.Panel header="6.如果不用“优加简历”写简历，可以购买导师服务吗？" key="6">
            <div className="question-desc">
              抱歉，导师辅导只针对在超级简历上制作的简历，建议你用超级简历创建一份简历或将已经写好的简历通过【导入简历】的功能上传。
              另外由于导入功能可能不会100%完全复制您的简历内容，需要您在下单前检查被修改的简历是否完整可读。
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="7.对导师辅导不满意怎么办？" key="7">
            <div className="question-desc">
              若对导师辅导不满意，可以添加导师助手微信或寻找在线客服及时反馈，我们会帮你与导师协商沟通重新修改或退款。
              注意：【校招版】和【在职版】导师辅导只在第一轮反馈后提供更换导师或退款服务。
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="8.我的简历为什么会被退单？" key="8">
            <div className="question-desc">
              导师辅导下单后首先会进行专业评估，如果简历不完善或存在信息过多缺失，会导致导师无法进行辅导。所以我们会对您的订单发起退款通知。
              建议您在下单之前根据超级简历的填写贴士、案例完善简历，同时注意提交求职意向信息。信息越完整导师越能提供更具针对性的辅导建议。
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};
export default Question;

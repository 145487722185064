import React, { useState } from 'react';
import './index.less';

const Team = () => {
  const [imgId, setImgId] = useState(0)
  const tutorList = [
    {
      id: 1,
      tutorAvatar: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor1.png?sign=bd80ba7f12bccb47149886b4180354cf&t=1651840629',
      tutorName: 'AKO老师',
      tutorPosition: '首席面试官',
      content: [
        '教育公司CEO',
        '职场密码创始人',
        'HR私享会联合创始人',
        '国家人力资源管理师',
        '辅导众多求职者进入世界500强企业',
        '人力资源管理经验'
      ]
    },
    {
      id: 2,
      tutorAvatar: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor2.png?sign=82b5656165ff204313f2600acf9e3c04&t=1651840653',
      tutorName: '政楠老师',
      tutorPosition: '职业经理人',
      content: [
        '某科技公司联合创始人',
        '20余年500强企业人力资源管理经验',
        '一线房地产+民企HRD工作经历',
        '2015年以来，累计为5000+名客户提供简历优化、面试辅导服务'
      ]
    },
    {
      id: 3,
      tutorAvatar: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor3.png?sign=e39bf9b81390931cb9b320cad47f8502&t=1651840763',
      tutorName: '伊始老师',
      tutorPosition: '职业经理人',
      content: [
        '千亿级主板上市企业人力资源总监',
        '211、985高校特聘管理咨询专家',
        '国家一级人力资源管理师、明星授课教师，Adidas,龙湖地产,中国烟草,国家电网等名企特聘管理咨询顾问'
      ]
    },
    {
      id: 4,
      tutorAvatar: 'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor4.png?sign=655adb30a90294910bbe98a79ae3e31b&t=1651840664',
      tutorName: '达菲老师',
      tutorPosition: '首席运营官',
      content: [
        '世界前10强企业高级人力管理人员',
        '211高校特聘管理咨询专家',
        '国家一级人力资源管理师、明星授课教师，Adidas,龙湖地产,中国烟草,国家电网等名企特聘管理咨询顾问'
      ]
    },
  ]
  return (
    <div className="content_box content_tuandui">
      <div className="team-icon">
        <img alt="true" className="team-icon_"
          src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/%E5%9B%A2%E9%98%9F%E7%AE%A1%E7%90%86icon.png?sign=a6d325c864fbed048f84a32c6ad6d3f6&t=1651730218'} />
      </div>
      <h3 style={{ fontWeight: 'bold' }}>导 师 团 队</h3>
      <div className="text_tit">严选200多位CEO、500强企业人力资源管理者、HRD等</div>
      <ul className="teacher_list">
        {tutorList.map((item, index) => {
          return (
            <li onMouseEnter={() => setImgId(item.id)}
              key={item.id} style={{
                marginLeft: `${item.id === 1 ? '0.5%' : '1%'}`
              }}>
              <img className="tutorAvatar" src={`${item.tutorAvatar}`} alt="true"
                style={{
                  top: `${imgId === item.id ? '-165px' : '-140px'}`,
                  transition: '0.5s'
                }}
              />
              <div className="teacher_card" style={{
                background: `${item.id % 2 === 0 ?
                  'linear-gradient(190deg, transparent 110px, #fff 0)' :
                  'linear-gradient(170deg, transparent 110px, #fff 0)'}`
              }} >
                <div className="tutorName">
                  <div className={item.id % 2 === 0 ? `tutorName-even` : `tutorName-odd`}>
                    {item.id % 2 === 0 ? (<>
                      <div className="tutorName-even-name">
                        <span style={{ fontFamily: 'Microsoft YaHei' }}>{item.tutorName}</span>
                      </div>
                      <span className="tutorName-even-position" style={{ float: 'right' }}>{item.tutorPosition}</span>
                    </>) :
                      (<>
                        <span className="tutorName-odd-position">{item.tutorPosition}</span>
                        <div className="tutorName-odd-name">
                          <span>{item.tutorName}</span>
                        </div>
                      </>)}
                  </div>
                </div>
                <div className="teacher_info">
                  {item.content.map((content, index) => {
                    return <p key={index}>{content}</p>
                  })}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      <div className="teacher_background">
        <div className="teacher_background-ellipse" />
      </div>
      <div className="btn_get" style={{ marginTop: '20px' }}>申请服务</div>
    </div>
  )
}

export default Team;


import Header from './components/Header';
import Team from './components/Team';
import Process from './components/Process';
import ChatRoom from './components/ChatRoom';
import React, { useState, createContext } from 'react';
import './index.less';
import Mock from 'mockjs';
import { useMount } from 'ahooks';
import SiderImg from '../../../components/SiderImg';

export const DisplayContext = createContext();
const delivery = Mock.mock({
  'data|1-3': [
    {
      'id|+1': 1,
      'title|1': [
        '马政楠',
        '李星皞',
        '王鑫',
        '刘凯',
        '苏小彪',
        '马海强',
        '马炳',
        '马玉梅',
        '禹常娥',
        '苏超',
      ],
      time: new Date(),
      type: 'text',
      position: 'left',
      date: new Date(),
      'text|1': [
        '【自动回复】欢迎来到优加实习',
        '【自动回复】请问有什么可以帮您的？',
        '【自动回复】回复“修改简历”、“模拟面试”获取1V1服务',
        '【自动回复】微信搜索“优加实习”，获取最新动态！',
      ],
    },
  ],
});
const Interviews = () => {
  useMount(() => {
    window.scrollTo(0, 0);
  });
  const [display_sideChat, setdisplay_sideChat] = useState('none');
  const [menuVisible, setMenuVisible] = useState(true);
  const handleContactClick = () => {
    setdisplay_sideChat('block');
    setMenuVisible(false);
  };
  const handleGoTopClick = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  return (
    <div className="index_content">
      <div className="banner">
        <div className="banner_bg"></div>
        <h2>求职服务，让求职更容易</h2>
        <p className="text">资深HR一对一服务，为求职保驾护航</p>
      </div>
      <Header />
      <Team />
      <Process />
      <div className="float_r">
        {menuVisible && (
          <ul>
            <li onClick={handleContactClick}>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/lianxi.png?sign=127e30ae445bde456d6b5c87884f5ca0&t=1650977915'
                }
                alt="true"
              />
              <p>联系客服</p>
            </li>
            <li className="gotop" onClick={handleGoTopClick}>
              <img
                src={
                  'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/gotop.png?sign=0a9eb42dbe7488c6381d95fb41d71cf3&t=1650978091'
                }
                alt="true"
              />
              <p>返回顶部</p>
            </li>
          </ul>
        )}
      </div>
      <SiderImg />
      <DisplayContext.Provider value={{ display_sideChat, setdisplay_sideChat,setMenuVisible, delivery }}>
        <ChatRoom />
      </DisplayContext.Provider>
    </div>
  );
};
export default Interviews;

import React from 'react';

const Recommend = () => {
  return (
    <div className="recommend">
      <div className="recommend-title">
        <span>推荐模版</span>
      </div>
      <div className="recommend-box">
        <div className="recommend-box-img">
          <img 
            className="recommend-box-img-resume"
            src={'https://files.wondercv.com/stu-temp-cn@2x.png'}
            alt="true"
          />
        </div>
        <div className="recommend-box-info">
          <div className="recommend-box-info-title">
            <h6>学生求职模版</h6>
            <span>推荐</span>
          </div>
          <p className="recommend-box-info-text">
          学生找实习或全职工作，有一定实习经历的同学
          </p>
          <div className="recommend-box-info-content">
            <div className="recommend-box-info-content-title">
              <div className="recommend-box-info-content-title-header">
                <img className="recommend-box-info-content-title-header-img"alt="true"
                src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/interview/interviews_icon/tutor4.png?sign=655adb30a90294910bbe98a79ae3e31b&t=1651840664'}/>
                <span>Lucy Yang · 字节校招 HR</span>
              </div>
              <img className="recommend-box-info-content-title-img"alt="true"
              src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/content.png?sign=420c08acf54c1764f3458331eef95139&t=1652355581'}/>
            </div>
            <p className="recommend-box-info-content-text">
            “这个是  HR  最想看到的学生简历，适合有实习经历的同学。
            没有花哨的样式，突出了实习期间的工作和成绩，能让  HR  在几秒内提取关键信息。
            值得一提的还有模板里提供的案例，语言专业精炼，非常推荐不擅长总结经历的同学参考看看。”</p>
          </div>
          <div className="recommend-box-info-btn">
            <button className="recommend-box-info-btn-cn">中文模版</button>
            <button className="recommend-box-info-btn-en">英文模版</button>
            <div className="recommend-box-info-btn-right">
              <a href="#modify" className="recommend-box-info-btn-right-server">
                <div className="recommend-box-info-btn-right-server-icon" >
                <img style={{width:'1.4em',height:'1.4em'}}
                alt="true"
                src={'https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/write.png?sign=8127141a5d0676e631d9a3961d492e8e&t=1652358549'}/>
                </div>
                <span>导师帮我写</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Recommend;

import React, { useState } from 'react';
import { Typography, Form, Input, message, Button } from 'antd';
import { saveBaseInfo } from '../../../../../common/ultils';
import { saveBaseInfoUsingPOST } from '../../../../../api/user-controller';
const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 14 },
  },
};
const { Title } = Typography;
const Account = () => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const data = await saveBaseInfoUsingPOST({
        ...values,
        province: values.province[0],
        city: values.province[1],
        birth: values.birth.endOf('d').valueOf(),
        country: '中国',
      });
      if (data) {
        message.success('保存成功');
        setIsEdit(true);
        saveBaseInfo({
          ...values,
          province: values.province[0],
          city: values.province[1],
          birth: values.birth.endOf('d').valueOf(),
          country: '中国',
        });
      }
    } catch (e) {
      message.error(e.error_msg);
    }
  };
  return (
    <div className="account">
      <Title level={3}>账号设置</Title>
      <div className="account-isEdit">
        <Button
          type="text"
          danger
          onClick={() => {
            setIsEdit(false);
          }}
        >
          编辑
        </Button>
      </div>
      <div className="account-from">
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="微信"
            name="weChat"
            rules={[
              {
                required: true,
                message: '请输入微信号！',
              },
            ]}
          >
            <Input placeholder="请填写微信号" disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="telePhone"
            rules={[
              {
                required: true,
                message: '请输入手机号！',
              },
            ]}
          >
            <Input placeholder="请填写手机号" disabled={isEdit} />
          </Form.Item>
        </Form>
        {!isEdit && (
          <div className="account-btn">
            <Button type="primary" danger onClick={handleSubmit}>
              保存
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Account;

import React, { useState } from 'react';
import { Modal, Result, Typography, Spin, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { fromateArray } from '../index';
import QRCodeCanvas from 'qrcode.react';
import { ORDERSTATUS, QRCODESTATUS } from 'pages/home/pages/common';
import imgUrl from './pay.png';
import {
  WechatPayControllerCreateNativePOST,
  WechatPayControllerQueryByOutTradeNoGet,
} from 'api/wei-xin-pay-controller';
import { useRequest, useUpdateEffect } from 'ahooks';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Paragraph, Link } = Typography;
const PayConfirm = ({
  coupons,
  count,
  isVisiable,
  conditionValue,
  serviceItem,
  setIsVisiable,
  servicePro,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [time, setTime] = useState(5);
  const [QrcodeValue, setQrcodeValue] = useState('');
  const [QrcodeStatus, setQrcodeStatus] = useState(QRCODESTATUS.LOADING);
  const history = useHistory();
  let timer;
  if (isSuccess) {
    timer = setInterval(() => {
      if(time===1){
        clearInterval(timer);
        history.push(`/personalCenter`);
        
      }
      setTime(time - 1);
    }, 1000);
  }
  function goOrderPage() {
    if (!time) {
      clearInterval(timer);
      history.push(`/personalCenter`);
    }
  }
  const payConfirm = () => {
    if (isSuccess) {
      history.push(`/personalCenter`);
    } else {
      message.error('订单支付未完成，请完成支付再试');
    }
  };
  const getWeChatPayQRCode = async (count) => {
    try {
      const data = await WechatPayControllerCreateNativePOST({
        total_fee: coupons ? 1 : count,
        body: `${serviceItem.title} ${fromateArray(servicePro).join('+')}`,
        attach: 'test',
      });
      if (data.result === 1) {
        setQrcodeValue(data.data.QrCode);
        setQrcodeStatus(QRCODESTATUS.SUCCESS);
        run(data.data.out_trade_no);
        setTimeout(() => {
          setQrcodeStatus(QRCODESTATUS.TIMEOUT);
        }, 1000 * 60 * 10);
      }
    } catch (e) {
      console.log('支付二维码获取失败', e.error_message);
      message.error('支付二维码获取失败', e.error_message);
      setQrcodeStatus(QRCODESTATUS.ERROR);
    }
  };

  const { run, cancel } = useRequest(WechatPayControllerQueryByOutTradeNoGet, {
    manual: true,
    pollingInterval: 500,
    onSuccess: (data, param) => {
      if (data.data.orderStatus === ORDERSTATUS.SUCCESS) {
        cancel();
        setIsSuccess(true);
        message.success('支付成功');
      }
    },
    onError: (e) => {
      cancel();
      message.error(e.error_msg, '支付失败');
    },
  });
  // useUpdateEffect(() => {}, [isSuccess]);
  useUpdateEffect(() => {
    isVisiable && getWeChatPayQRCode(count);
  }, [isVisiable]);
  return (
    <Modal
      footer={null}
      open={isVisiable}
      onCancel={() => {
        setIsVisiable(false);
      }}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
            textAlign: 'center',
            fontSize: 24,
          }}
        >
          订单支付
        </div>
      }
      className="pay-modal"
      width={700}
      centered
    >
      <div style={{ textAlign: 'center' }}>
        <div className="detail">
          <div style={{ marginBottom: 5 }}>
            <p>
              <span className="detail-title">订单名称：</span>
              <span className="detail-desc">{`${serviceItem.title} ${fromateArray(servicePro).join(
                '+',
              )}`}</span>
            </p>
          </div>
          <div>
            <p>
              <span className="detail-title">服务条件：</span>
              <span className="detail-desc">{`${conditionValue.education}${conditionValue.experience}${conditionValue.luanguage}${conditionValue.isStudyAbroad} ${conditionValue.isExpedited}${conditionValue.industry}`}</span>
            </p>
          </div>
          <div>
            <p>
              <span className="detail-title">剩余时间：</span>
              {/* <Countdown title="Million Seconds" value={deadline} format="HH:mm:ss:SSS" /> */}
            </p>
          </div>
        </div>

        <div className="contant">
          <div className="pay">
            {isSuccess ? (
              <div className="pay-success">
                <Result
                  status="success"
                  title="支付成功"
                  subTitle="订单编号: 2017182818828182881 ."
                />
                <br />
                <Paragraph>
                  支付成功，<Link style={{ color: '#ff3d3d' }}>{time}</Link> s后自动跳转{' '}
                  <Link style={{ color: '#ff3d3d' }} onClick={()=>{goOrderPage()}}>
                    订单详情页面 &gt;
                  </Link>
                </Paragraph>
              </div>
            ) : (
              <>
                <div className="Qrcode">
                  <QRCodeCanvas
                    value={QrcodeValue}
                    size={95}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'M'}
                    includeMargin={false}
                    imageSettings={{
                      src: 'https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/%E7%BD%91%E7%AB%99logo/logo.JPG?sign=bcb9d07d10304f151e62e7146bbd40b6&t=1648001975',
                      x: undefined,
                      y: undefined,
                      height: 24,
                      width: 24,
                      excavate: true,
                    }}
                  />
                  {QrcodeStatus !== QRCODESTATUS.SUCCESS && (
                    <div
                      className="Qr-mask"
                      onClick={() => {
                        QrcodeStatus !== QRCODESTATUS.LOADING &&
                          isVisiable &&
                          getWeChatPayQRCode(count);
                      }}
                    >
                      {QrcodeStatus === QRCODESTATUS.LOADING && (
                        <Spin indicator={antIcon} style={{ color: '#ff3d3d' }}></Spin>
                      )}

                      {QrcodeStatus === QRCODESTATUS.ERROR && (
                        <>
                          <RedoOutlined
                            style={{ fontSize: 24, color: '#ff3d3d', display: 'inline-block' }}
                          />
                          <span style={{ fontSize: 17, color: '#ff3d3d', display: 'inline-block' }}>
                            二维码错误
                          </span>
                        </>
                      )}
                      {QrcodeStatus === QRCODESTATUS.TIMEOUT && (
                        <>
                          <RedoOutlined
                            style={{ fontSize: 24, color: '#ff3d3d', display: 'inline-block' }}
                          />
                          <span style={{ fontSize: 17, color: '#ff3d3d', display: 'inline-block' }}>
                            二维码过期
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="paycontent">
                  <p>
                    <span className="count">¥ {coupons ? 1 : count}</span>
                  </p>
                  <p className="pay-icon">
                    <img src={imgUrl} width={24} alt="1" />
                  </p>
                  <p>使用微信，扫码支付</p>
                </div>
                <div className="pay-success-btn">
                  <button
                    onClick={() => {
                      payConfirm();
                    }}
                  >
                    支付成功
                  </button>
                  <div className="pay-error">遇到问题? 请咨询右下角客服</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PayConfirm;

import React, { useEffect } from 'react';
import AnimationBtn from '../AnimationBtn';
import { useHistory } from 'react-router-dom';
import BannerImage from '../BannerImage';
import './index.less';

const Brand = () => {
  const history = useHistory();
  useEffect(() => {
    document.querySelectorAll('.brand .animation-item').forEach((el, index, arr) => {
      el.style.transitionDelay = `${index * 0.05}s`;
      el.style.opacity = index === arr.length - 1 ? '0.8' : '1';
      el.style.transform = 'translateY(0px)';
    });
  }, []);

  return (
    <section className="brand">
      <div className="calculate-left bg-info">
        <h1 className="name">
          {name.split('').map((text, index) => (
            <span className="animation-item" key={`${text}-${index}`}>
              {text}
            </span>
          ))}
        </h1>
        <h1 className="slogon">
          {slogon.split('').map((text, index) => (
            <span className="animation-item" key={`${text}-${index}`}>
              {text}
            </span>
          ))}
        </h1>
        <div className="btns">
          <AnimationBtn
            className="realization animation-item"
            //             onClick={() => {
            //               if (userInfo) {
            //                 history.push('/management/app');
            //               } else {
            //                 const homeDom = document.querySelector('.home');
            //                 const scrollPlaceholder = document.querySelector(
            //                   '.union-category-scroll-placeholder',
            //                 );
            //                 if (homeDom && scrollPlaceholder) {
            //                   scrollPlaceholder.style.height = '0px';
            //                   scrollTopSmoothTo(homeDom, homeDom.scrollHeight - window.innerHeight);
            //                 }
            //               }
            //             }}
            onClick={async () => {
              history.push('/make');
            }}
            text="开始使用"
          />
          <a href="https://youjia.sx.cn/blog/" target="_blank" rel="noreferrer">
            <AnimationBtn className="to-ad animation-item" text="学习更多" />
          </a>
        </div>
        <div className="description animation-item">
          优加实习是由大学生创办的求职干货分享平台，每周为求职者提供最新最全面的互联网名企以及国企实习信息，提供最优质的职场资讯，让社会求职者在求职过程更加顺利，让在校求职生从求学到工作的过渡过程走的更加舒畅！拿offer拿到手软！
          注：本平台现阶段所有抽奖活动奖品均为概念奖品，请理性看待，最终解释权归优加实习。
        </div>
      </div>
      <div className="calculate-right bg-info">
        {/* <canvas id="canvas3d" width="500" height="500"></canvas> */}

        <BannerImage />
      </div>
    </section>
  );
};
const name = '我们在大学';
const slogon = '总要去实习一次吧!';
export default Brand;

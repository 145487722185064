import React from 'react';
import animationData from 'common/lottie/Designer.json';
import book1 from 'common/lottie/book1.json';
import book4 from 'common/lottie/book4.json';

import { useHistory } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import salesman from 'common/lottie/salesman.json';
import Lottie from 'react-lottie';
import './index.less';

const defaultOptions1 = {
  autoplay: true,
  loop: true,
  animationData: animationData,
};
const defaultOptions2 = {
  autoplay: true,
  loop: true,
  animationData: salesman,
};
const defaultOptions3 = {
  autoplay: true,
  loop: true,
  animationData: book1,
};
const defaultOptions4 = {
  autoplay: true,
  loop: true,
  animationData: book4,
};

const IntroProduction = () => {
  const history = useHistory();
  const OptimizeResume = (order) => {
    history.push('/choseType');
    localStorage.setItem('order', order);
  };
  return (
    <div className="mid-content pr">
      <OverPack className="mid-page" playScale={[0.2, 0.1]}>
        <QueueAnim type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
          <div className="mid-tit">服务介绍</div>
          <QueueAnim key="mid-list pr" type="bottom" className="mid-list pr">
            <div className="clear-list">
              <div className="lottie-anima">
                <Lottie options={defaultOptions1} width={180} />
              </div>
              <div className="f-tit">简历模版</div>
              <div className="text">
                优加实习提供大量精美简历模版，涵盖岗位广，AI智能分析简历等功能
              </div>
              <button class="card-button">立即使用</button>
            </div>
            <div className="clear-list">
              <div className="lottie-anima">
                <Lottie options={defaultOptions3} width={180} />
              </div>
              <div className="f-tit">简历优化</div>
              <div className="text">
                专家代笔，规范简历用词，根据求职意向提炼经历打磨要点，提高求职成功率
              </div>
              <button class="card-button" onClick={() => OptimizeResume(1)}>
                立即优化
              </button>
            </div>
            <div className="clear-list">
              <div className="lottie-anima">
                <Lottie options={defaultOptions4} width={180} />
              </div>
              <div className="f-tit">职业咨询</div>
              <div className="text">
                解答职业困惑，制定职业发展计划及阶段性目标，职业规划师为你量身打造职场晋升之路，打好职场第一步
              </div>
              <button class="card-button" onClick={() => OptimizeResume(2)}>
                立即咨询
              </button>
            </div>
            <div className="clear-list">
              <div className="lottie-anima">
                <Lottie options={defaultOptions2} width={180} />
              </div>
              <div className="f-tit">面试模拟</div>
              <div className="text">
                与导师一对一面试演练，提前发现问题，个性制定应答策略，提高面试通过率。
              </div>
              <button class="card-button" onClick={() => OptimizeResume(3)}>
                立即选择
              </button>
            </div>
          </QueueAnim>
          <QueueAnim>
            <div className="indexButton" onClick={() => OptimizeResume(1)}>
              立即优化
            </div>
          </QueueAnim>
        </QueueAnim>
      </OverPack>
    </div>
  );
};
export default IntroProduction;

/*
 * @Author: maxueming maxueming@kuaishou.com
 * @Date: 2022-09-05 20:20:11
 * @LastEditors: maxueming maxueming@kuaishou.com
 * @LastEditTime: 2023-08-03 21:47:10
 * @FilePath: /greenet-resume-project/src/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { initAxios } from './common/axios';
import store from './reduxStroe';

initAxios();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import './index.less';

interface PropsType {
  setIsVisiable: (isVisiable: boolean) => void;
}

const OpenVip = (props:PropsType) => {
  const {setIsVisiable} = props
  const data = [
    {
      id: 1,
      vipSum: '20000',
      vText: '尊享会员用户',
      resumeSum: '10000',
      rText: '大牛简历模板',
      vipGroup: '99',
      vGText: '专属求职群',
    },
  ];
  return (
    <div className="container_sum">
      <div className="sum_main">
        {data.map((item) => {
          return (
            <div className="main_intro" key={item.id}>
              <div className="intro_item">
                <p>{item.vipSum}+</p>
                <p>
                  {item.vipSum}+ {item.vText}
                </p>
              </div>
              <div className="intro_item">
                <p>{item.resumeSum}+</p>
                <p>
                  {item.resumeSum}+ {item.rText}
                </p>
              </div>
              <div className="intro_item">
                <p>{item.vipGroup}+</p>
                <p>
                  {item.vipGroup}+ {item.vGText}
                </p>
              </div>
            </div>
          );
        })}
        <div className="main_btn" onClick={()=>setIsVisiable(true)}>
          <p>立即开通</p>
        </div>
      </div>
    </div>
  );
};

export default OpenVip;

import { Empty, Tabs } from 'antd';
import React from 'react';
import ResumeCard from './resumeCard';
import Mock from 'mockjs';

const ResumeTab = () => {
  const { TabPane } = Tabs;

  function callback(key) {
    console.log(key);
  }
  //   const resumeTab = [
  //     {
  //       tabId: '1',
  //       tabName: '我的简历',
  //     },
  //     {
  //       tabId: '2',
  //       tabName: '收藏模版',
  //     },
  //     {
  //       tabId: '3',
  //       tabName: '回收站',
  //     },
  //   ];
  const cardData = Mock.mock({
    'data|1-4': [
      {
        'id|+1': 1,
        'name|1': [['马学明'], ['刘凯'], ['禹常娥'], ['马玉梅'], ['李星皞']],
        'resumeScore|1': [[80], [60], [45], [100], [95], [50], [37]],
        'time|1': [
          ['4月22日'],
          ['4月19日'],
          ['4月28日'],
          ['5月3日'],
          ['5月5日'],
          ['5月1日'],
          ['5月6日'],
        ],
        'language|1': [['中文'], ['英文']],
      },
    ],
  });
  return (
    <div className="resumeTab">
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab={`我的简历(${cardData.data.length})`} key="1">
          {cardData.data.map((data) => {
            return <ResumeCard cardData={data} key={data.id} />;
          })}
        </TabPane>
        <TabPane tab="收藏模版" key="2">
          <Empty description={<p>暂无收藏模版</p>} style={{ marginTop: '20px' }} />
          <img
            className="bottom-img"
            alt="true"
            src={'https://files.wondercv.com/PC/cvs/resume_template.png'}
          />
        </TabPane>
        <TabPane tab="回收站" key="3">
          <Empty description={<p>暂无已删除简历</p>} style={{ marginTop: '20px' }} />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default ResumeTab;

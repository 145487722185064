import axios from 'axios';
import message from './message';
import Cookies from 'js-cookie';

export function getCookieValue(name) {
  const strCookie = document.cookie;
  const arrCookie = strCookie.split(';');
  for (let i = 0; i < arrCookie.length; i++) {
    const c = arrCookie[i].split('=');
    if (c[0].trim() === name) {
      return c[1];
    }
  }
  return '';
}

export function uuid(m) {
  const d = m > 16 ? 16 : m;
  const num = Math.random().toString();
  if (num.substr(num.length - d, 1) === '0') return uuid(d);
  return num.substring(num.length - d);
}

export function initAxios() {
  axios.interceptors.request.use(
    (config) => {
      const cookiesId = Cookies.get('xxl_sso_sessionid');
      const sessionId = sessionStorage.getItem('preLogin');

      if (!cookiesId && !sessionId) {
        message.error('', '登录状态变化，请刷新页面！');
        window.location.href = '/'
        return Promise.reject();
      }
      config.headers.Accept = 'application/json';
      config.headers.csrfToken = decodeURIComponent(getCookieValue('csrfToken'));
      config.headers.startTime = Date.now();
      config.params = config.params ?? {};
      if (getCookieValue('xxl_sso_sessionid')) {
        config.params['xxl_sso_sessionid'] = getCookieValue('xxl_sso_sessionid');
      }
      config.params.reqid = uuid(16);
      return config;
    },
    async (err) => {
      console.error(err);
      return Promise.reject(err);
    },
  );

  axios.interceptors.response.use(
    (res) => {
      const { data } = res;
      // eslint-diasble-next-line
      if (data && +data.result === 1) {
        return res;
      }

      throw res?.data || {};
    },
    async (err) => {
      console.error(err || {});

      return Promise.reject(err || {});
    },
  );
}

export default axios;

import axios from 'axios';

export async function loginWechatUsingGET() {
  // const headers = Object.create(null);
  const result = await axios.request({
    url: `/api/resume/web/loginWechat`,
    method: 'get',
  });
  return result.data;
}

export async function checkLoginStatusUsingGET(params) {
  // const headers = Object.create(null);
  const result = await axios.request({
    url: `/api/resume/web/checkLoginStatus`,
    method: 'get',
    params,
  });
  return result.data;
}

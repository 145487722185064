import React from 'react';
import { Button } from 'antd';

export const page1 = [
  {
    title: '简历修改',
    content: '网站内设有数据监测埋点，助你时刻掌握站点效果，鉴别有效渠道',
    src: 'https://gw.alipayobjects.com/zos/rmsportal/ONvKhpRAmkCfdEhkUZkJ.png',
  },
  {
    title: '模拟面试',
    content: '企业版支持以团队管理的方式，多人协作完成营销站点，共享资源',
    src: 'https://gw.alipayobjects.com/zos/rmsportal/oxmXLgGjCeXfYPcVSbKg.png',
  },
  {
    title: '优加内推',
    content: '支持可视化编辑、自由拖拽排版、实时预览、在线访问加速等功能',
    src: 'https://gw.alipayobjects.com/zos/rmsportal/MmROsqZndrFBrIspzLlL.png',
  },
];

export const page3 = [
  {
    title: '个人版',
    content: [
      <p key="1">支持 H5 站点制作</p>,
      <p key="2">支持模板管理</p>,
      <p key="3">支持数据分析</p>,
    ],
    // exp: '免费使用',
    svg: (
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="98.8500478%" id="linearGradient-1">
            <stop stopColor="#FFD24C" offset="0%" />
            <stop stopColor="#FFB800" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M11.2,19.9763991 L1.43301577,25.9589289
           C0.542750835,26.5042392 1.20441457e-15,27.4731633 1.33226763e-15,28.5171628
              L0,28.5171628 L0,30 C1.3527075e-16,31.1045695 0.8954305,32 2,32
              L30,32 C31.1045695,32 32,31.1045695 32,30 L32,30 L32,28.5391533
              C32,27.4832633 31.4449138,26.5051178 30.53843,25.9636469 L30.53843,25.9636469
              L20.8,20.1465799 L20.8,18.1051172 C22.2729985,16.7867478 23.2,14.8708611 23.2,12.7384615
              L23.2,7.2 C23.2,3.2235498 19.9764502,-7.30462051e-16 16,0
              C12.0235498,7.30462051e-16 8.8,3.2235498 8.8,7.2 L8.8,7.2
              L8.8,12.7384615 C8.8,14.8708611 9.72700154,16.7867478 11.2,18.1051172 L11.2,19.9763991 Z"
          fill="#D9D9D9"
        />
        <path
          d="M11.2,19.9763991 L1.43301577,25.9589289
        C0.542750835,26.5042392 1.20441457e-15,27.4731633 1.33226763e-15,28.5171628
              L0,28.5171628 L0,30 C1.3527075e-16,31.1045695 0.8954305,32 2,32
              L30,32 C31.1045695,32 32,31.1045695 32,30 L32,30 L32,28.5391533
              C32,27.4832633 31.4449138,26.5051178 30.53843,25.9636469 L30.53843,25.9636469
              L20.8,20.1465799 L20.8,18.1051172 C22.2729985,16.7867478 23.2,14.8708611 23.2,12.7384615
              L23.2,7.2 C23.2,3.2235498 19.9764502,-7.30462051e-16 16,0
              C12.0235498,7.30462051e-16 8.8,3.2235498 8.8,7.2 L8.8,7.2
              L8.8,12.7384615 C8.8,14.8708611 9.72700154,16.7867478 11.2,18.1051172 L11.2,19.9763991 Z"
          fill="url(#linearGradient-1)"
          className="icon-hover"
        />
      </svg>
    ),
  },
  {
    title: '企业版',
    content: [
      <p key="1">支持 H5 站点制作</p>,
      <p key="2">支持模板管理，实现模板共享</p>,
      <p key="3">支持工作组管理，实现多人协作</p>,
      <p key="4">支持自定义发布审批流</p>,
      <p key="5">支持自定义域名</p>,
      <p key="6">支持成员管理</p>,
    ],
    svg: (
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <path
          d="M22.3555358,32 L9.93657149,32 L2,32 C0.8954305,32 1.3527075e-16,31.1045695 0,30
          L0,18 C-1.3527075e-16,16.8954305 0.8954305,16 2,16 L2,16 L6.78535432,16 L6.78535432,2
        L6.78535432,2 C6.78535432,0.8954305 7.68078482,2.02906125e-16 8.78535432,0 L21.7853543,0
        C22.8899238,-2.02906125e-16 23.7853543,0.8954305 23.7853543,2 L23.7853543,7 L30,7
        C31.1045695,7 32,7.8954305 32,9 L32,30 C32,31.1045695 31.1045695,32 30,32 L22.3555358,32
        Z M10,4 L10,8 L14,8 L14,4 L10,4 Z M10,12 L10,16 L14,16 L14,12 L10,12 Z M10,20 L10,24 L14,24
        L14,20 L10,20 Z M3,20 L3,24 L7,24 L7,20 L3,20 Z M17,4 L17,8 L21,8 L21,4 L17,4 Z M17,12 L17,16
        L21,16 L21,12 L17,12 Z M17,20 L17,24 L21,24 L21,20 L17,20 Z M24,20 L24,24 L28,24 L28,20 L24,20
        Z M24,12 L24,16 L28,16 L28,12 L24,12 Z"
          fill="#D9D9D9"
        />
        <path
          d="M22.3555358,32 L9.93657149,32 L2,32 C0.8954305,32 1.3527075e-16,31.1045695 0,30
          L0,18 C-1.3527075e-16,16.8954305 0.8954305,16 2,16 L2,16 L6.78535432,16 L6.78535432,2
        L6.78535432,2 C6.78535432,0.8954305 7.68078482,2.02906125e-16 8.78535432,0 L21.7853543,0
        C22.8899238,-2.02906125e-16 23.7853543,0.8954305 23.7853543,2 L23.7853543,7 L30,7
        C31.1045695,7 32,7.8954305 32,9 L32,30 C32,31.1045695 31.1045695,32 30,32 L22.3555358,32
        Z M10,4 L10,8 L14,8 L14,4 L10,4 Z M10,12 L10,16 L14,16 L14,12 L10,12 Z M10,20 L10,24 L14,24
        L14,20 L10,20 Z M3,20 L3,24 L7,24 L7,20 L3,20 Z M17,4 L17,8 L21,8 L21,4 L17,4 Z M17,12 L17,16
        L21,16 L21,12 L17,12 Z M17,20 L17,24 L21,24 L21,20 L17,20 Z M24,20 L24,24 L28,24 L28,20 L24,20
        Z M24,12 L24,16 L28,16 L28,12 L24,12 Z"
          fill="url(#linearGradient-1)"
          className="icon-hover"
        />
      </svg>
    ),
  },
  {
    title: '企业 VIP 版',
    content: [
      <p key="1">支持通过 OpenAPI 调用制作流程</p>,
      <p key="2">可以指定可用模板，企业内共享模板</p>,
      <p key="3">支持创建多个企业级账户</p>,
      <p key="4">支持自定义发布审批流</p>,
      <p key="5">支持自定义域名</p>,
    ],
    svg: (
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <path
          d="M21.1504124,8.82079742 L16.5552541,3.32558694
          C16.5324137,3.29827282 16.5071873,3.27304601 16.4798736,3.25020514
        C16.2256714,3.03763068 15.8472742,3.07137659 15.6346998,3.32557875
        L15.6346998,3.32557875 L10.966694,8.90770327 L8.07603504,6.76607715
        L8.07603504,6.76607715 C7.80977712,6.56881247 7.43401779,6.62474254 7.23675311,6.89100046
        C7.13505771,7.02826378 7.09669461,7.20236809 7.13128245,7.36966081 L8.29535756,13
        L15.9998162,13 L23.7045714,13 L24.8686535,7.36962696
        C24.9357456,7.0451192 24.727069,6.72766477 24.4025612,6.66057265
        C24.2352715,6.62598542 24.0611703,6.66434651 23.9239079,6.76603807
        L21.1504124,8.82079742 Z M2,14 L30,14 C31.1045695,14 32,14.8954305 32,16
        L32,30 C32,31.1045695 31.1045695,32 30,32 L2,32 C0.8954305,32 1.3527075e-16,31.1045695 0,30
        L0,16 C-1.3527075e-16,14.8954305 0.8954305,14 2,14 L2,14 Z M16,2 C15.4477153,2 15,1.55228475 15,1
        C15,0.44771525 15.4477153,0 16,0 C16.5522847,0 17,0.44771525 17,1 C17,1.55228475 16.5522847,2 16,2 Z
        M6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 C6.55228475,4 7,4.44771525 7,5
        C7,5.55228475 6.55228475,6 6,6 Z M26,6 C25.4477153,6 25,5.55228475 25,5
        C25,4.44771525 25.4477153,4 26,4 C26.5522847,4 27,4.44771525 27,5
        C27,5.55228475 26.5522847,6 26,6 Z M14.0979687,19.25
        C14.1648524,19.04375 14.177013,18.94375 14.1526917,18.825
        C14.1101294,18.6375 13.9094785,18.5 13.7149079,18.5
        C13.6237029,18.5 13.5264176,18.53125 13.459534,18.59375
        C13.368329,18.675 13.3257667,18.75625 13.2588831,18.9625 L11.0821247,25.80625
        L8.90536631,18.9625 C8.83848268,18.75625 8.79592036,18.675 8.7047154,18.59375
        C8.63783176,18.53125 8.54054647,18.5 8.44934151,18.5
        C8.25477093,18.5 8.05412002,18.6375 8.0115577,18.825
        C7.98723638,18.94375 7.99939704,19.04375 8.06628068,19.25 L10.5713769,26.98125
        C10.6260999,27.15625 10.6929835,27.3 10.7659475,27.36875
        C10.8632328,27.46875 10.9544378,27.5 11.0821247,27.5
        C11.2037313,27.5 11.2949363,27.46875 11.3983019,27.36875
        C11.4712659,27.3 11.5381495,27.15625 11.5928725,26.98125
        L14.0979687,19.25 Z M16.5726622,19.15 C16.5726622,18.9 16.5605016,18.81875 16.4875376,18.7
        C16.4145736,18.58125 16.274726,18.5 16.1044768,18.5 C15.9342275,18.5 15.7943799,18.58125 15.7214159,18.7
        C15.648452,18.81875 15.6362913,18.9 15.6362913,19.15 L15.6362913,26.85
        C15.6362913,27.1 15.648452,27.18125 15.7214159,27.3 C15.7943799,27.41875 15.9342275,27.5 16.1044768,27.5
        C16.274726,27.5 16.4145736,27.41875 16.4875376,27.3 C16.5605016,27.18125 16.5726622,27.1 16.5726622,26.85
        L16.5726622,19.15 Z M19.0777579,19.13125 L19.0777579,26.85
        C19.0777579,27.1 19.0899186,27.18125 19.1628825,27.3
        C19.2358465,27.41875 19.3756941,27.5 19.5459434,27.5
        C19.7161926,27.5 19.8560402,27.41875 19.9290042,27.3
        C20.0019682,27.18125 20.0141288,27.1 20.0141288,26.85
        L20.0141288,23.7875 L22.3124938,23.7875 C23.8872995,23.7875 25,22.75 25,21.1625
        C25,19.575 23.8872995,18.55 22.3124938,18.55 L19.6432287,18.55
        C19.4973007,18.55 19.3331318,18.55 19.2054449,18.68125
        C19.0777579,18.8125 19.0777579,18.98125 19.0777579,19.13125 Z M22.2395299,19.4
        C23.3218287,19.4 24.0575487,19.9875 24.0575487,21.1625
        C24.0575487,22.3375 23.3218287,22.9375 22.2395299,22.9375 L20.0141288,22.9375
        L20.0141288,19.4 L22.2395299,19.4 Z"
          fill="#D9D9D9"
        />
        <path
          d="M21.1504124,8.82079742 L16.5552541,3.32558694
          C16.5324137,3.29827282 16.5071873,3.27304601 16.4798736,3.25020514
        C16.2256714,3.03763068 15.8472742,3.07137659 15.6346998,3.32557875
        L15.6346998,3.32557875 L10.966694,8.90770327 L8.07603504,6.76607715
        L8.07603504,6.76607715 C7.80977712,6.56881247 7.43401779,6.62474254 7.23675311,6.89100046
        C7.13505771,7.02826378 7.09669461,7.20236809 7.13128245,7.36966081 L8.29535756,13
        L15.9998162,13 L23.7045714,13 L24.8686535,7.36962696
        C24.9357456,7.0451192 24.727069,6.72766477 24.4025612,6.66057265
        C24.2352715,6.62598542 24.0611703,6.66434651 23.9239079,6.76603807
        L21.1504124,8.82079742 Z M2,14 L30,14 C31.1045695,14 32,14.8954305 32,16
        L32,30 C32,31.1045695 31.1045695,32 30,32 L2,32 C0.8954305,32 1.3527075e-16,31.1045695 0,30
        L0,16 C-1.3527075e-16,14.8954305 0.8954305,14 2,14 L2,14 Z M16,2 C15.4477153,2 15,1.55228475 15,1
        C15,0.44771525 15.4477153,0 16,0 C16.5522847,0 17,0.44771525 17,1 C17,1.55228475 16.5522847,2 16,2 Z
        M6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 C6.55228475,4 7,4.44771525 7,5
        C7,5.55228475 6.55228475,6 6,6 Z M26,6 C25.4477153,6 25,5.55228475 25,5
        C25,4.44771525 25.4477153,4 26,4 C26.5522847,4 27,4.44771525 27,5
        C27,5.55228475 26.5522847,6 26,6 Z M14.0979687,19.25
        C14.1648524,19.04375 14.177013,18.94375 14.1526917,18.825
        C14.1101294,18.6375 13.9094785,18.5 13.7149079,18.5
        C13.6237029,18.5 13.5264176,18.53125 13.459534,18.59375
        C13.368329,18.675 13.3257667,18.75625 13.2588831,18.9625 L11.0821247,25.80625
        L8.90536631,18.9625 C8.83848268,18.75625 8.79592036,18.675 8.7047154,18.59375
        C8.63783176,18.53125 8.54054647,18.5 8.44934151,18.5
        C8.25477093,18.5 8.05412002,18.6375 8.0115577,18.825
        C7.98723638,18.94375 7.99939704,19.04375 8.06628068,19.25 L10.5713769,26.98125
        C10.6260999,27.15625 10.6929835,27.3 10.7659475,27.36875
        C10.8632328,27.46875 10.9544378,27.5 11.0821247,27.5
        C11.2037313,27.5 11.2949363,27.46875 11.3983019,27.36875
        C11.4712659,27.3 11.5381495,27.15625 11.5928725,26.98125
        L14.0979687,19.25 Z M16.5726622,19.15 C16.5726622,18.9 16.5605016,18.81875 16.4875376,18.7
        C16.4145736,18.58125 16.274726,18.5 16.1044768,18.5 C15.9342275,18.5 15.7943799,18.58125 15.7214159,18.7
        C15.648452,18.81875 15.6362913,18.9 15.6362913,19.15 L15.6362913,26.85
        C15.6362913,27.1 15.648452,27.18125 15.7214159,27.3 C15.7943799,27.41875 15.9342275,27.5 16.1044768,27.5
        C16.274726,27.5 16.4145736,27.41875 16.4875376,27.3 C16.5605016,27.18125 16.5726622,27.1 16.5726622,26.85
        L16.5726622,19.15 Z M19.0777579,19.13125 L19.0777579,26.85
        C19.0777579,27.1 19.0899186,27.18125 19.1628825,27.3
        C19.2358465,27.41875 19.3756941,27.5 19.5459434,27.5
        C19.7161926,27.5 19.8560402,27.41875 19.9290042,27.3
        C20.0019682,27.18125 20.0141288,27.1 20.0141288,26.85
        L20.0141288,23.7875 L22.3124938,23.7875 C23.8872995,23.7875 25,22.75 25,21.1625
        C25,19.575 23.8872995,18.55 22.3124938,18.55 L19.6432287,18.55
        C19.4973007,18.55 19.3331318,18.55 19.2054449,18.68125
        C19.0777579,18.8125 19.0777579,18.98125 19.0777579,19.13125 Z M22.2395299,19.4
        C23.3218287,19.4 24.0575487,19.9875 24.0575487,21.1625
        C24.0575487,22.3375 23.3218287,22.9375 22.2395299,22.9375 L20.0141288,22.9375
        L20.0141288,19.4 L22.2395299,19.4 Z"
          fill="url(#linearGradient-1)"
          className="icon-hover"
        />
      </svg>
    ),
  },
];

export const page4 = [
  'https://gw.alipayobjects.com/zos/rmsportal/kJPmZttedahfIvsZDGTd.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/ktLOdfjNyExgKyWjiCvm.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/gNNxbrNqlHdnRuMjUXrL.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/qWaneWxxMVpASpiCOcsT.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/kyCpvqWFloOpAOqzOlBg.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/bBzJNEnPvyhNUhyHipvO.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/UbEpCMXGBmDgkJXfIgMx.svg',
  'https://gw.alipayobjects.com/zos/rmsportal/xsWGKOdkRoJnzHIZlRiD.svg',
];

export const footer = [
  { text: '解决方案', src: '#' },
  { text: '使用帮助', src: '#' },
  { text: '模板开发文档', src: '#' },
  { text: '模板开发工具', src: '#' },
  { text: '联系我们', src: 'mailto:zhao.wuz@antfin.com' },
];
export const userInfo = [
  {
    id: 1,
    url: 'https://files.wondercv.com/%E5%BC%A0%E6%99%93%E6%B6%B5.png',
    name: '张涵予',
    desc: '基金人力资源招聘总监',
    content:
      '我每年会看上千份简历，好的简历基本上一眼就能看出来。大多数求职者即便失败了也不会意识到，从简历的样式和逻辑他就已经被刷下去了。说实话，10秒内看不到重点的简历就会让我很烦躁，尤其是一些各种图表、文字没有内容、简历过长等等信息展示不清楚的简历都不会再看第二眼。超级简历生成的简历非常清晰专业，让人眼前一亮，我希望我收到的每份简历都能是这个样子。',
  },
  {
    id: 2,
    url: 'https://files.wondercv.com/%E5%BC%A0%E6%99%93%E6%B6%B5.png',
    name: '马学明',
    desc: '基金人力资源招聘总监',
    content:
      '我每年会看上千份简历，好的简历基本上一眼就能看出来。大多数求职者即便失败了也不会意识到，从简历的样式和逻辑他就已经被刷下去了。说实话，10秒内看不到重点的简历就会让我很烦躁，尤其是一些各种图表、文字没有内容、简历过长等等信息展示不清楚的简历都不会再看第二眼。超级简历生成的简历非常清晰专业，让人眼前一亮，我希望我收到的每份简历都能是这个样子。',
  },
  {
    id: 3,
    url: 'https://files.wondercv.com/%E5%BC%A0%E6%99%93%E6%B6%B5.png',
    name: '王 鑫',
    desc: '基金人力资源招聘总监',
    content:
      '我每年会看上千份简历，好的简历基本上一眼就能看出来。大多数求职者即便失败了也不会意识到，从简历的样式和逻辑他就已经被刷下去了。说实话，10秒内看不到重点的简历就会让我很烦躁，尤其是一些各种图表、文字没有内容、简历过长等等信息展示不清楚的简历都不会再看第二眼。超级简历生成的简历非常清晰专业，让人眼前一亮，我希望我收到的每份简历都能是这个样子。',
  },
  {
    id: 4,
    url: 'https://files.wondercv.com/%E5%BC%A0%E6%99%93%E6%B6%B5.png',
    name: '刘凯',
    desc: '基金人力资源招聘总监',
    content:
      '我每年会看上千份简历，好的简历基本上一眼就能看出来。大多数求职者即便失败了也不会意识到，从简历的样式和逻辑他就已经被刷下去了。说实话，10秒内看不到重点的简历就会让我很烦躁，尤其是一些各种图表、文字没有内容、简历过长等等信息展示不清楚的简历都不会再看第二眼。超级简历生成的简历非常清晰专业，让人眼前一亮，我希望我收到的每份简历都能是这个样子。',
  },
];

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children =
    typeof item.children === 'string' && item.children.match(isImg)
      ? React.createElement('img', { src: item.children, alt: 'img' })
      : item.children;
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children,
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};
